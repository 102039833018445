@import url("https://fonts.googleapis.com/icon?family=Material+Icons");



.navbar-logo {
  height: 34px;
  width: auto;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ed1650 !important;
}

.mat-mdc-menu-panel.mat-mdc-menu-panel {
  top:20px!important;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent
  .mat-radio-ripple
  .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #ed1650 !important;
}


.mat-mdc-button.mat-unthemed {
  width: 9pc;
}

.mat-expansion-panel-header:not([aria-disabled=true]) {
  cursor: pointer;
  padding-left: 15px!important;
}


.title-icon {
  color: #1b0088;
  display: none;
  font-size: 30px !important;
}

.mat-primary .mat-mdc-option:not(.mat-mdc-option-multiple) .mat-pseudo-checkbox-checked {
  background: none !important;
}

/* breadcrumb */

.breadcrumb {
  background-color: transparent;
  -webkit-padding-start: 0px;
}

.breadcrumb-item a {
  color: #535353 !important;
  text-decoration-line: none;
  font-size: 13px;
}

.active a {
  color: #ec254f !important;
}

.breadcrumb-item+.breadcrumb-item::before {
  content: "|";
}

.title {
  color: #1b0088;
  font-family: LatamSansRegular;
  font-size: 25px;
}

.subtitle {
  color: #1c0189;
  font-family: LatamSansRegular;
  font-size: 20px;
}

/* sidenav-btn */

.radial-btn {
  background-color: #3f4353;
  border: none;
  border-radius: 50%;
  color: white;
  cursor: default !important;
  font-family: LatamSansRegular !important;
  margin-left: 9px;
  margin-top: -80px;
  padding: 8px 6px;
  position: absolute;
  width: 70%;
}

.sidenav {
  background-color: #111;
  height: 100%;
  left: 0;
  overflow-x: hidden;
  padding-top: 60px;
  position: fixed;
  top: 0;
  transition: 0.5s;
  width: 0;
  z-index: 1;
}

.sidenav a {
  color: #818181;
  display: block;
  font-size: 25px;
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  transition: 0.3s;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.sidenav .closebtn {
  font-size: 36px;
  margin-left: 50px;
  position: absolute;
  right: 25px;
  top: 0;
}

.active-red {
  color: #ed1650;
}

.latam-sans {
  font-family: LatamSansRegular;
}

.background-red {
  background-color: #e80845 !important;
  color: #ffffff !important;
  margin: 3px !important;
}

.background-red:hover:not([disabled]) {
  background-color: #ff1857!important;
}

.background-blue {
  background-color: #311894 !important;
  color: #ffffff !important;
  margin: 3px !important;
}

.background-blue:hover:not([disabled]) {
  background-color: #2C1F61 !important;
}

.background-gray {
  background: #ebebeb !important;
}

.btn-volver {
  background-color: transparent;
  color: #363636 !important;
  margin: 3px !important;
}

.btn-volver:hover {
  background-color: #F0ECFF;
}

button {
  font-family: LatamSansRegular !important;
}

button[disabled] {
  box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12) !important;
}

button:focus {
  outline: none !important;
}

button:disabled, button[disabled], a.mat-mdc-button:disabled, a.mat-button[disabled] {
  background-color: #f4f4f4 !important;
  color: #757575 !important;
  margin: 3px;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
}

/* swal2 */

.swal2-modal {
  border-radius: 0 !important;
  border-bottom: 2px solid #ec254f !important;
}

.swal2-title {
  font-family: LatamSansRegular;
}

.swal2-title p {
  color: #1b0088;
  font-size: 22px;
  font-weight: normal !important;
}

.swal2-content p {
  color: #1b0088;
  font-family: LatamSansRegular;
  font-size: 20px;
  text-align: center !important;
}

.swal2-icon:first-child {
  margin-top: 70px;
}

.swal2-popup .swal2-actions {
  justify-content: flex-end;
  margin-bottom: 0px;
  margin-right: 0px;
  margin-top: 30px;
}

.swal2-confirm {
  background-color: #ec254f !important;
  border-radius: 0 !important;
  font-size: 14px !important;
  font-weight: normal !important;
}

.swal2-cancel {
  background: #ebebeb !important;
  border-radius: 0 !important;
  color: rgba(0, 0, 0, 0.86) !important;
  font-size: 14px !important;
  font-weight: normal !important;
}

.swal2-confirm:active {
  background-color: #d11f46 !important;
}

.confirm-button {
  width: 25%;
}

.cancel-button {
  width: 25%;
}

/* ini estilo error page  */

.row.d-flex {
  height: 600px;
}

.flex-content {
  display: flex;
  align-content: stretch;
}

.content-message {
  background: rgba(255, 255, 255, 0.8);
  border-bottom: 2px solid #20b9b3;
  font-family: LatamSansRegular;
  padding: 30px;
}

.content-message img {
  display: block;
  margin: 0 auto;
}

.content-message span.second-message {
  margin: 35px 0 35px 0;
}

.content-message a {
  background-color: #31D2CC;
  color: #fff;
  display: block;
  padding: 10px 30px 10px 30px;
  margin: 0 auto;
  text-align: center;
  text-decoration: none;
  transition: box-shadow .2s ease-in, background-color .2s ease-in, color .2s ease-in;
  width: 200px;
}

.content-message a:hover {
  background-color: #20b9b3;
}

h1.principal-text {
  color: #190087;
  font-size: 30px;
  margin: 50px 0 50px 0;
  text-align: center;
}

.error-type {
  display: block;
  margin: 0 auto;
  text-align: center;
  width: 400px;
}

.error-type span {
  display: block;
  font-family: LatamSansBold;
  font-size: 150px;
  line-height: 1;
  margin-left: 115px;
  margin-top: -28px;
}

.error-type h2 {
  color: #fff;
  font-family: LatamSansLight;
  font-size: 60px;
}
.cb-slideshow, .cb-slideshow:after {
  height: 100%;
  left: 0px;
  list-style: none;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 0;
}

.cb-slideshow:after {
  content: '';
  background: transparent url(assets/images/error-page/pattern.png) repeat top left;
}

.cb-slideshow li span {
  animation: imageAnimation 36s linear infinite 0s;
  background-position: 50% 50%;
  background-repeat: none;
  background-size: cover;
  color: transparent;
  height: 100%;
  left: 0px;
  opacity: 0;
  position: absolute;
  top: 0px;
  width: 100%;
  z-index: 0;
  -moz-animation: imageAnimation 36s linear infinite 0s;
  -ms-animation: imageAnimation 36s linear infinite 0s;
  -o-animation: imageAnimation 36s linear infinite 0s;
  -webkit-animation: imageAnimation 36s linear infinite 0s;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

.cb-slideshow li div {
  animation: titleAnimation 36s linear infinite 0s;
  bottom: 0px;
  color: #fff;
  left: 0px;
  opacity: 0;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: 1000;
  -moz-animation: titleAnimation 36s linear infinite 0s;
  -ms-animation: titleAnimation 36s linear infinite 0s;
  -o-animation: titleAnimation 36s linear infinite 0s;
  -webkit-animation: titleAnimation 36s linear infinite 0s;
}

.cb-slideshow li div h3 {
  background: rgba(0, 0, 0, 0.7);
  bottom: 0;
  font-family: LatamSansRegular;
  font-size: 12px;
  padding: 9px 70px 7px 12px;
  position: absolute;
  right: 0;
}

.cb-slideshow li:nth-child(1) span {
  background-image: url(assets/images/error-page/1.jpg)
}

.cb-slideshow li:nth-child(2) span {
  animation-delay: 6s;
  background-image: url(assets/images/error-page/2.jpg);
  -moz-animation-delay: 6s;
  -ms-animation-delay: 6s;
  -o-animation-delay: 6s;
  -webkit-animation-delay: 6s;
}

.cb-slideshow li:nth-child(3) span {
  animation-delay: 12s;
  background-image: url(assets/images/error-page/3.jpg);
  -moz-animation-delay: 12s;
  -ms-animation-delay: 12s;
  -o-animation-delay: 12s;
  -webkit-animation-delay: 12s;
}

.cb-slideshow li:nth-child(4) span {
  animation-delay: 18s;
  background-image: url(assets/images/error-page/4.jpg);
  -moz-animation-delay: 18s;
  -ms-animation-delay: 18s;
  -o-animation-delay: 18s;
  -webkit-animation-delay: 18s;
}

.cb-slideshow li:nth-child(5) span {
  animation-delay: 24s;
  background-image: url(assets/images/error-page/5.jpg);
  -moz-animation-delay: 24s;
  -ms-animation-delay: 24s;
  -o-animation-delay: 24s;
  -webkit-animation-delay: 24s;
}

.cb-slideshow li:nth-child(6) span {
  animation-delay: 30s;
  background-image: url(assets/images/error-page/6.jpg);
  -moz-animation-delay: 30s;
  -ms-animation-delay: 30s;
  -o-animation-delay: 30s;
  -webkit-animation-delay: 30s;
}

.cb-slideshow li:nth-child(2) div {
  animation-delay: 6s;
  -moz-animation-delay: 6s;
  -ms-animation-delay: 6s;
  -o-animation-delay: 6s;
  -webkit-animation-delay: 6s;
}

.cb-slideshow li:nth-child(3) div {
  animation-delay: 12s;
  -moz-animation-delay: 12s;
  -ms-animation-delay: 12s;
  -o-animation-delay: 12s;
  -webkit-animation-delay: 12s;
}

.cb-slideshow li:nth-child(4) div {
  animation-delay: 18s;
  -moz-animation-delay: 18s;
  -ms-animation-delay: 18s;
  -o-animation-delay: 18s;
  -webkit-animation-delay: 18s;
}

.cb-slideshow li:nth-child(5) div {
  animation-delay: 24s;
  -moz-animation-delay: 24s;
  -ms-animation-delay: 24s;
  -o-animation-delay: 24s;
  -webkit-animation-delay: 24s;
}

.cb-slideshow li:nth-child(6) div {
  animation-delay: 30s;
  -moz-animation-delay: 30s;
  -ms-animation-delay: 30s;
  -o-animation-delay: 30s;
  -webkit-animation-delay: 30s;
}

/* end estilo error page  */

.close-icon {
  color: #4b4b4b;
}

/* SCROLL BAR */

body::-webkit-scrollbar {
  background: rgb(194, 194, 194);
  border-radius: 10px;
  width: 6px;
}

body::-webkit-scrollbar-thumb {
  background-color: rgba(44, 62, 80, 0.5);
  border-radius: 5px;
  outline: 1px solid slategrey;
}

.modal-content {
  border: none;
  border-radius: 0px;;
}

.modal-content::-webkit-scrollbar {
  background: rgb(194, 194, 194);
  border-radius: 10px;
  margin-right: 10px;
  width: 6px;
}

.modal-content::-webkit-scrollbar-thumb {
  background-color: rgba(44, 62, 80, 0.5);
  border-radius: 5px;
  height: 100px;
  margin-right: 10px;
  outline: 1px solid slategrey;
}

.modal-content-body::-webkit-scrollbar {
  background: rgb(194, 194, 194);
  border-radius: 10px;
  margin-right: 10px;
  width: 6px;
}

.modal-content-body::-webkit-scrollbar-thumb {
  background-color: rgba(44, 62, 80, 0.5);
  border-radius: 5px;
  margin-right: 10px;
  outline: 1px solid slategrey;
}

textarea {
  border-radius: 0!important;
}

textarea::-webkit-scrollbar {
  background: rgb(194, 194, 194);
  border-radius: 10px;
  width: 6px;
}

textarea::-webkit-scrollbar-thumb {
  background-color: rgba(44, 62, 80, 0.5);
  border-radius: 5px;
  outline: 1px solid slategrey;
}

.swal2-popup .swal2-styled:focus {
  box-shadow: none !important;
}

body.swal2-shown {
  padding-right: 0px !important;
}

.table-btn {
  font-family: LatamSansRegular;
  font-size: 10px;
  height: auto;
  padding: 10px;
  width: auto;
}

.d-none {
  display: none;
}

.no-data-found {
  font-family: LatamSansRegular;
  font-size: 14px;
  margin: 5px 0px 5px 10px;
}

.highlight {
  background: #f3f3f3;
  cursor: default;
}

.invisible-button:disabled {
  box-shadow: none !important;
}

main.container {
  min-width: 900px;
}

a {
  font-family: LatamSansRegular!important;
}

/* material */

.mat-datepicker-toggle-active {
  color: #1b0088;
}

.mat-calendar-body-selected {
  background-color: #1b0088;
}

.mat-mdc-tooltip {
  white-space: pre-line;
  word-wrap: break-word;
}

.mat-mdc-form-field {
  display: block !important;
}

.mdc-text-field {
  color: rgba(0, 0, 0, 1);
  background-color: transparent !important;
}

mat-form-field label, .mat-error {
  font-family: LatamSansRegular !important;
  font-size: 14px !important;
}

.mat-mdc-form-field-icon-suffix {
  position: absolute !important;
  right: -15px;
}

.mat-mdc-icon-button svg{
  width: 20px !important;
  height: 20px !important;
}

.mat-mdc-icon-button .mat-mdc-button-persistent-ripple {
  display: none !important;
}

.mdc-text-field--filled .mdc-floating-label--float-above {
  transform: translateY(-106%) scale(1.0) !important;
}

mat-form-field.mat-mdc-form-field {
  --mat-mdc-form-field-floating-label-scale: 1.0;
}

.mat-mdc-form-field-focus-overlay {
  background-color: transparent !important;
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) {
  padding-left: 0px !important;
}

.mat-mdc-form-field-infix {
  padding-bottom: 2px !important;
  min-height: 5px !important;
}

.mat-mdc-button:hover .mat-button-focus-overlay, .mat-mdc-outlined-button:hover .mat-button-focus-overlay {
  opacity: 0!important;
}

a.mat-mdc-button:hover, a.mat-button:focus {
  text-decoration: none;
}

.mat-mdc-input-element:disabled {
  color: rgba(0, 0, 0, 1)
}

textarea {
  width: 100%;
}

mat-error {
  float: right;
}

.mat-cell {
  color: #4e4e4e;
  font-family: LatamSansRegular;
  font-size: 12px;
  justify-content: center !important;
  text-align: center;
}

.mat-mdc-cell {
  color: #4e4e4e;
  font-family: LatamSansRegular;
  font-size: 12px;
  justify-content: center !important;
  text-align: center !important;
}


.mat-elevation-z8 {
  box-shadow: none;
}

.mat-menu-content {
  padding: 0px 0px 0px 0px !important;
}

.btn-mat-menu {
  text-align: center !important;
}

.btn-mat-menu:hover {
  background-color: #01b5ad !important;
}

.btn-mat-menu:hover .item-mat-menu {
  display: block;
}

.btn-mat-menu:hover>.item-mat-menu {
  color: #ffffff;
}

.item-mat-menu {
  font-family: LatamSansRegular;
  color: #4e4e4e;
  font-size: 12px;
  text-align: center;
}

.mat-mdc-raised-button {
  border-radius: 0px !important;
}

.mat-header-row, .mat-row {
  padding: 0px 0px 0px 0px !important;
  border-color: #b3b3b3 !important;
}

.no-data-found-row {
  font-size: 13px;
  font-family: LatamSansRegular;
}

.loading-data-error-row {
  font-size: 13px;
  height: 70px;
}

.mat-mdc-option:hover:not(.mat-option-disabled) {
  background-color: #01b5ad;
  color: #fff;
  transition: background-color 250ms linear;
}

.mat-mdc-option.mat-selected:not(.mat-mdc-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, .04)!important;
}

.mat-option-text {
  font-family: LatamSansRegular !important;
}

.mat-primary .mat-mdc-option.mat-selected:not(.mat-option-disabled) {
  color: #01b5ad;
}

.mat-drawer-inner-container::-webkit-scrollbar {
  display: none;
}

.mat-primary .mat-pseudo-checkbox-checked, .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #01b5ad;
}


mat-option:hover>mat-pseudo-checkbox {
  border: 2px solid #ffffff !important;
}

.mat-mdc-select-panel {
  background: rgb(255, 255, 255);
}

.mat-mdc-select-panel::-webkit-scrollbar {
  background: rgb(194, 194, 194);
  border-radius: 10px;
  width: 6px;
}

.mat-mdc-select-panel::-webkit-scrollbar-thumb {
  background-color: rgba(44, 62, 80, 0.5);
  border-radius: 5px;
  outline: 1px solid slategrey;
}

.mat-mdc-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-mdc-paginator-navigation-previous.mat-mdc-icon-button {
  margin-right: 5px !important;
}

.mat-tooltip-show {
  background: rgba(44, 62, 80, 0.5);
}

.mat-header-cell {
  font-family: LatamSansBold;
  color: #1b0088;
  font-size: 13px !important;
  justify-content: center !important;
  display: flex;
  flex-direction: column;
  margin-left: 0% !important;
}

.mat-mdc-header-cell {
  font-family: LatamSansBold;
  color: #1b0088;
  font-size: 13px !important;
  justify-content: center !important;
  display: flex;
  flex-direction: column;
  margin-left: 0% !important;
}

.mat-header-cell>div>button {
  font-family: LatamSansBold !important;
}

.mat-mdc-header-cell>div>button {
  font-family: LatamSansBold !important;
}

mat-header-cell.date-hour-arrow .mat-sort-header-arrow {
  margin: 10% 0px 0px 0px !important;
}

.input-content {
  margin-right: 5px;
  margin-left: 5px;
}

.input-control {
  border: 0;
  border-bottom: 1px solid #ccc;
  border-radius: 0;
  font-family: LatamSansRegular;
  font-size: 11px;
  text-align: center;
}

input[type=number].input-control::-webkit-inner-spin-button, input[type=number].input-control::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

mat-header-cell.total-item {
  color: red;
}

.table-paginator {
  border: 1px solid #e4d7d7;
  border-top: 0px;
  margin: 0px 30px;
}

/* Orden ascendente y descendente de las tablas */

.mat-sort-header-arrow {
  margin: 0% 0px 0px 2px !important;
  opacity: 0;
  transform: translateY(25%);
}

.mat-sort-header-pointer-left.ng-trigger.ng-trigger-leftPointer {
  transform: rotate(-45deg);
}

.mat-sort-header-pointer-right.ng-trigger.ng-trigger-rightPointer {
  transform: rotate(45deg);
}

.mat-sort-header-indicator.ng-trigger.ng-trigger-indicator {
  transform: translateY(0px);
}

.mat-sort-header-container {
  display: inline-flex !important;
}

/* inicio mat-dialog */

.full-width-dialog, #cdk-overlay-1.cdk-overlay-pane {
  max-width: 80vw !important;
}

.mat-dialog-container {
  background: #fefcfc !important;
  border-radius: 0px !important;
  box-shadow: none !important;
  height: auto !important;
  overflow: inherit !important;
  padding: 0px 0px !important;
}

.cdk-overlay-dark-backdrop {
  background: rgba(27, 27, 27, 0.8) !important;
}

.cdk-overlay-container {
  z-index: 1000 !important;
}

/* fin mat-dialog*/

/* inicio mat-tab mat-tab-group */

.mat-tab-header, .mat-tab-nav-bar {
  border-bottom: 1px solid #01b5ad !important;
}

.mat-tab-label {
  background-color: #f3f3f3;
  color: #1b1b1b;
  font-family: LatamSansRegular;
  font-size: 14px;
  height: 36px !important;
  margin-right: 2px;
  opacity: 1 !important;
  width: 165px !important;
}

.mat-tab-label.mat-tab-label-active {
  background-color: #01b5ad !important;
  color: #fff ;
}

/* Prueba Material 17 */


.mdc-tab {
  --mat-tab-header-label-text-font: 'LatamSansRegular';
  letter-spacing: 0 !important;
  min-width: 160px;
  background-color: #f3f3f3 !important;
  margin-right: 2px !important;
  font-size: 14px;
  height: 36px !important;
}

.mdc-tab--active {
  background-color: #01b5ad !important;
}

.mat-tab-header-active-label-text-color{
  color: #fff !important ;
}

.mat-mdc-tab-group, .mdc-tab--active  {
  --mat-tab-header-active-label-text-color: #fff !important;
  --mat-tab-header-active-focus-label-text-color: #fff !important;
  --mdc-tab-indicator-active-indicator-color: #01b5ad !important; 
  --mat-tab-header-active-focus-indicator-color: #01b5ad !important;
  --mat-tab-header-active-hover-indicator-color: #01b5ad !important;
  --mdc-secondary-navigation-tab-container-height: 36px !important;
}



/* inicio mat-checkbox */

.mat-checkbox-layout .mat-checkbox-label {
  color: rgba(0, 0, 0, 1);
  font-family: LatamSansRegular;
  font-size: 14px;
}

.mat-mdc-checkbox-layout .mat-mdc-checkbox-label {
  color: rgba(0, 0, 0, 1);
  font-family: LatamSansRegular;
  font-size: 14px;
}


.mat-checkbox-inner-container {
  height: 20px !important;
  width: 20px !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #01b5ad !important;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: #01b5ad !important;
}


.mat-checkbox-ripple {
  height: 0 !important;
  left: 0 !important;
  top: 0 !important;
  width: 0 !important;
}

.mat-mdc-checkbox .mdc-checkbox__ripple {
  height: 0 !important;
  left: 0 !important;
  top: 0 !important;
  width: 0 !important;
}

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__background {
  background-color: transparent !important;
  border-color: #1b0088 !important;
}

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background{
  background-color: #01b5ad !important;
  color: white !important;
  border-color: #01b5ad !important;
}


.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:disabled:checked~.mdc-checkbox__background{
  background-color: #01b5ad !important;
  color: white !important;
  border-color: #01b5ad !important;
}

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled~.mdc-checkbox__background .mdc-checkbox__checkmark{
  color: white !important
}

/* fin mat-checkbox */

/* select-rechazar */


.mat-select-value {
  color: #282828 !important;
  font-family: LatamSansRegular !important;
}

/* select-activar-agencia */


.select-tier .mat-select-value-text {
  font-family: LatamSansRegular;
  font-size: 14px;
  padding-left: 5px;
}


.select-flag .mat-select-value {
  max-width: 40px;
  width: 40px !important;
}

/* fin-select-activar-agencia */

/* stepper */

.mat-step-header:hover {
  background-color: transparent !important;
}

.mat-step-header .mat-step-icon {
  background-color: #01b5ad !important;
  color: #fff;
  margin-left: auto !important;
  margin-right: auto !important;
}

.mat-step-header-ripple {
  bottom: auto !important;
  left: auto !important;
  right: auto !important;
  top: auto !important;
}

.mat-step-header .mat-step-icon-not-touched {
  background-color: #dadada !important;
  color: #fff;
  margin-left: auto !important;
  margin-right: auto !important;
  position: relative !important;
}

.mat-horizontal-stepper-header {
  display: block !important;
  height: inherit !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.mat-horizontal-stepper-header-container {
  padding-bottom: 30px;
  padding-left: 22%;
  padding-right: 22%;
  padding-top: 50px;
}

.mat-horizontal-content-container {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.mat-stepper-horizontal-line {
  height: 1.5rem !important;
  margin: 0 -18% !important;
}

.mat-step-icon .mat-icon {
  font-size: 24px !important;
  height: 24px !important;
  width: 24px !important;
}

.mat-step-icon, .mat-step-icon-not-touched {
  font-family: LatamSansLight;
  font-size: 24px !important;
  height: 60px !important;
  width: 60px !important;
}

.mat-step-icon .mat-icon.check {
  font-size: 24px !important;
  height: 24px !important;
  width: 24px !important;
}

.mat-step-label {
  color: #565559 !important;
  font-family: LatamSansLight;
  font-size: 16px;
}

@media screen and (max-width: 767px) {
  .mat-horizontal-stepper-header-container {
    padding-left: 0%;
    padding-right: 0%;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .mat-horizontal-stepper-header-container {
    padding-left: 10%;
    padding-right: 10%;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .mat-horizontal-stepper-header-container {
    padding-left: 20%;
    padding-right: 20%;
  }
}

/* fin stepper */

/* mat-slide-toggle */

/* .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: rgb(0,150,136);
  --mdc-switch-selected-handle-color: rgb(0,150,136);
  --mdc-switch-selected-hover-state-layer-color: rgb(0,150,136);
  --mdc-switch-selected-pressed-state-layer-color: rgb(0,150,136);
  --mdc-switch-selected-focus-handle-color: rgb(0,150,136);
  --mdc-switch-selected-hover-handle-color: rgb(0,150,136);
  --mdc-switch-selected-pressed-handle-color: rgb(0,150,136);
  --mdc-switch-selected-focus-track-color: rgb(127,202,195)f;
  --mdc-switch-selected-hover-track-color: rgb(127,202,195);
  --mdc-switch-selected-pressed-track-color: rgb(127,202,195);
  --mdc-switch-selected-track-color: rgb(127,202,195);
} */

.mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-handle-color: rgb(0,150,136) !important;
  --mdc-switch-selected-hover-handle-color: rgb(0,150,136) !important;
  --mdc-switch-selected-focus-handle-color: rgb(0,150,136) !important;
  --mdc-switch-selected-pressed-handle-color: rgb(0,150,136) !important;

  --mdc-switch-selected-track-color: rgb(127,202,195) !important;
  --mdc-switch-selected-hover-track-color: rgb(127,202,195) !important;
  --mdc-switch-selected-focus-track-color: rgb(127,202,195) !important;
  --mdc-switch-selected-pressed-track-color: rgb(127,202,195) !important;

  --mdc-switch-selected-hover-state-layer-color: rgb(0,150,136) !important;
  --mdc-switch-selected-focus-state-layer-color: rgb(0,150,136) !important;
  --mdc-switch-selected-pressed-state-layer-color: rgb(0,150,136) !important;
}


/* fin mat-slide-toggle */

/* mat-card */

.mat-mdc-card {
  margin: 30px 0px;
  padding: 0px !important;
}

.mat-mdc-card:not([class*=mat-elevation-z]) {
  box-shadow: none !important;
}

/* fin mat-card */

/* button actions */

.action {
  background-color: transparent;
  border: none;
  color: #01b5ad;
  font-size: 12px;
}

.action:hover {
  text-decoration: underline;
}

.action[disabled] {
  background-color: transparent !important;
  color: #cccccc !important;
}

/* fin button actions */

/* icon check */

.spinner-border {
  margin-left: 10px;
}

.icon-green {
  color: #01b5ad;
  margin-left: 10px;
}

.icon-green-hidden {
  color: #ffffff;
}

/* fin icon check */

.mr-15px {
  margin-right: 15px;
}

.mr-17px {
  margin-right: 17px;
}

.min-w-540 {
  min-width: 540px;
}

.min-w-720 {
  min-width: 720px;
}

.min-w-740 {
  min-width: 740px;
}

.min-w-760 {
  min-width: 760px;
}

.min-w-900 {
  min-width: 900px;
}

/* spinner */

.loading {
  display: table;
  min-height: 60vh;
  width: 100%;
}

.loading-div {
  display: table-cell;
  margin: 0 auto;
  text-align: center;
  vertical-align: middle;
}

.loading-span {
  display: block;
  font-family: LatamSansLight;
  font-size: 12px;
  margin-top: 20px;
  text-align: center;
}

.spinner, mat-spinner>svg {
  animation: rotator 1.4s linear infinite;
  -webkit-animation: rotator 1.4s linear infinite;
}

.path, mat-spinner>svg>circle {
  animation: dash 1.4s ease-in-out infinite, colors 5.6s ease-in-out infinite;
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  transform-origin: center;
  -webkit-animation: dash 1.4s ease-in-out infinite, colors 5.6s ease-in-out infinite;
  -webkit-transform-origin: center;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: rgb(27, 0, 136);
}

.refresh-spinner {
  background-color: transparent;
  border: none;
  color: #1b0088;
  margin-top: 10px;
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    -webkit-transform: rotate(270deg);
  }
}

@-webkit-keyframes rotator {
  0% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
    -webkit-transform: rotate(270deg);
  }
}

@keyframes colors {
  0% {
    stroke: #4285F4;
  }
  25% {
    stroke: #DE3E35;
  }
  50% {
    stroke: #F7C223;
  }
  75% {
    stroke: #1B9A59;
  }
  100% {
    stroke: #4285F4;
  }
}

@-webkit-keyframes colors {
  0% {
    stroke: #4285F4;
  }
  25% {
    stroke: #DE3E35;
  }
  50% {
    stroke: #F7C223;
  }
  75% {
    stroke: #1B9A59;
  }
  100% {
    stroke: #4285F4;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 187;
  }
  50% {
    stroke-dashoffset: 46.75;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    transform: rotate(450deg);
    -webkit-transform: rotate(450deg);
  }
}

@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 187;
  }
  50% {
    stroke-dashoffset: 46.75;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    transform: rotate(450deg);
    -webkit-transform: rotate(450deg);
  }
}

/* fin spinner */

/* popup session expire */

.icon-session-time {
  color: #d33;
  display: block;
  font-size: 80px;
  margin-bottom: 20px;
}

.session-modal-title {
  font-size: 28px !important;
  margin: 30px 0px 0px 0px;
}

.session-countdown-number {
  display: block;
  font-size: 40px;
  margin-top: 10px;
}

/* fin popup session expire*/

/* Animation for the slideshow images */

@-webkit-keyframes imageAnimation {
  0% {
    opacity: 0;
    -webkit-animation-timing-function: ease-in;
  }
  8% {
    opacity: 1;
    -webkit-animation-timing-function: ease-out;
  }
  17% {
    opacity: 1
  }
  25% {
    opacity: 0
  }
  100% {
    opacity: 0
  }
}

@-moz-keyframes imageAnimation {
  0% {
    opacity: 0;
    -moz-animation-timing-function: ease-in;
  }
  8% {
    opacity: 1;
    -moz-animation-timing-function: ease-out;
  }
  17% {
    opacity: 1
  }
  25% {
    opacity: 0
  }
  100% {
    opacity: 0
  }
}

@-o-keyframes imageAnimation {
  0% {
    opacity: 0;
    -o-animation-timing-function: ease-in;
  }
  8% {
    opacity: 1;
    -o-animation-timing-function: ease-out;
  }
  17% {
    opacity: 1
  }
  25% {
    opacity: 0
  }
  100% {
    opacity: 0
  }
}

@-ms-keyframes imageAnimation {
  0% {
    opacity: 0;
    -ms-animation-timing-function: ease-in;
  }
  8% {
    opacity: 1;
    -ms-animation-timing-function: ease-out;
  }
  17% {
    opacity: 1
  }
  25% {
    opacity: 0
  }
  100% {
    opacity: 0
  }
}

@keyframes imageAnimation {
  0% {
    opacity: 0;
    animation-timing-function: ease-in;
  }
  8% {
    opacity: 1;
    animation-timing-function: ease-out;
  }
  17% {
    opacity: 1
  }
  25% {
    opacity: 0
  }
  100% {
    opacity: 0
  }
}

/* Animation for the title */

@-webkit-keyframes titleAnimation {
  0% {
    opacity: 0
  }
  8% {
    opacity: 1
  }
  17% {
    opacity: 1
  }
  19% {
    opacity: 0
  }
  100% {
    opacity: 0
  }
}

@-moz-keyframes titleAnimation {
  0% {
    opacity: 0
  }
  8% {
    opacity: 1
  }
  17% {
    opacity: 1
  }
  19% {
    opacity: 0
  }
  100% {
    opacity: 0
  }
}

@-o-keyframes titleAnimation {
  0% {
    opacity: 0
  }
  8% {
    opacity: 1
  }
  17% {
    opacity: 1
  }
  19% {
    opacity: 0
  }
  100% {
    opacity: 0
  }
}

@-ms-keyframes titleAnimation {
  0% {
    opacity: 0
  }
  8% {
    opacity: 1
  }
  17% {
    opacity: 1
  }
  19% {
    opacity: 0
  }
  100% {
    opacity: 0
  }
}

@keyframes titleAnimation {
  0% {
    opacity: 0
  }
  8% {
    opacity: 1
  }
  17% {
    opacity: 1
  }
  19% {
    opacity: 0
  }
  100% {
    opacity: 0
  }
}

/* Show at least something when animations not supported */

.no-cssanimations .cb-slideshow li span {
  opacity: 1;
}

@media screen and (max-width: 1140px) {
  .error-type {
    width: 300px;
    margin-top: 27px;
    margin-left: 0;
  }
  .error-type span {
    margin-left: 50px;
  }
}

/* LATAM FONTS AND ICON FONTS */

@font-face {
  font-family: 'LatamSansItalicBlack';
  font-style: italic;
  font-weight: 900;
  src: url('assets/fonts/LatamSans-BlackItalic.woff2') format('woff2'), url('assets/fonts/LatamSans-BlackItalic.woff') format('woff');
}

@font-face {
  font-family: 'LatamSansBold';
  font-style: normal;
  font-weight: bold;
  src: url('assets/fonts/LatamSans-Bold.woff2') format('woff2'), url('assets/fonts/LatamSans-Bold.woff') format('woff');
}

@font-face {
  font-family: 'LatamSansItalicRegular';
  font-style: italic;
  font-weight: normal;
  src: url('assets/fonts/LatamSans-RegularItalic.woff2') format('woff2'), url('assets/fonts/LatamSans-RegularItalic.woff') format('woff');
}

@font-face {
  font-family: 'LatamSansLight';
  font-style: normal;
  font-weight: 300;
  src: url('assets/fonts/LatamSans-Light.woff2') format('woff2'), url('assets/fonts/LatamSans-Light.woff') format('woff');
}

@font-face {
  font-family: 'LatamSansItalicHairline';
  font-style: italic;
  font-weight: 300;
  src: url('assets/fonts/LatamSans-HairlineItalic.woff2') format('woff2'), url('assets/fonts/LatamSans-HairlineItalic.woff') format('woff');
}

@font-face {
  font-family: 'LatamSansItalicLight';
  font-style: italic;
  font-weight: 300;
  src: url('assets/fonts/LatamSans-LightItalic.woff2') format('woff2'), url('assets/fonts/LatamSans-LightItalic.woff') format('woff');
}

@font-face {
  font-family: 'LatamSansBlack';
  font-style: normal;
  font-weight: 900;
  src: url('assets/fonts/LatamSans-Black.woff2') format('woff2'), url('assets/fonts/LatamSans-Black.woff') format('woff');
}

@font-face {
  font-family: 'LatamSansRegular';
  font-style: normal;
  font-weight: normal;
  src: url('assets/fonts/LatamSans-Regular.woff2') format('woff2'), url('assets/fonts/LatamSans-Regular.woff') format('woff');
}

@font-face {
  font-family: 'LatamSansItalicBold';
  font-style: italic;
  font-weight: bold;
  src: url('assets/fonts/LatamSans-BoldItalic.woff2') format('woff2'), url('assets/fonts/LatamSans-BoldItalic.woff') format('woff');
}

@font-face {
  font-family: 'LatamSansHairline';
  font-style: normal;
  font-weight: 300;
  src: url('assets/fonts/LatamSans-Hairline.woff2') format('woff2'), url('assets/fonts/LatamSans-Hairline.woff') format('woff');
}

@font-face {
  font-family: 'LatamSansExtended';
  font-style: normal;
  font-weight: normal;
  src: url('assets/fonts/LatamSans-Extended.woff2') format('woff2'), url('assets/fonts/LatamSans-Extended.woff') format('woff');
}

@font-face {
  font-family: latam-icons-light;
  font-style: normal;
  font-weight: 400;
  src: url(assets/fonts/latam-icons/eot/Latam_Icons-Light.eot#iefix) format('embedded-opentype');
}

@font-face {
  font-family: latam-icons-light;
  font-style: normal;
  font-weight: 400;
  src: url(assets/fonts/latam-icons/woff2/Latam_Icons-Light.woff2) format('woff2'), url(assets/fonts/latam-icons/woff/Latam_Icons-Light.woff) format('woff'), url(assets/fonts/latam-icons/ttf/Latam_Icons-Light.ttf) format('truetype');
}

[class*=icon-light], [class^=icon-light] {
  font-family: latam-icons-light;
  text-transform: none;
  -webkit-font-smoothing: antialiased
}

.icon-light-air001:before {
  content: "\e043"
}

.icon-light-air002:before {
  content: "\e044"
}

.icon-light-air003:before {
  content: "\e045"
}

.icon-light-air004:before {
  content: "\e046"
}

.icon-light-air005:before {
  content: "\e047"
}

.icon-light-air006:before {
  content: "\e048"
}

.icon-light-air007:before {
  content: "\e049"
}

.icon-light-air008:before {
  content: "\e04A"
}

.icon-light-air009:before {
  content: "\e04B"
}

.icon-light-air010:before {
  content: "\e04C"
}

.icon-light-air011:before {
  content: "\e04D"
}

.icon-light-air012:before {
  content: "\e04E"
}

.icon-light-air013:before {
  content: "\e210"
}

.icon-light-air014:before {
  content: "\e275"
}

.icon-light-air015:before {
  content: "\e215"
}

.icon-light-air016:before {
  content: "\e216"
}

.icon-light-air017:before {
  content: "\e21D"
}

.icon-light-air018:before {
  content: "\e23F"
}

.icon-light-air019:before {
  content: "\e242"
}

.icon-light-air020:before {
  content: "\e245"
}

.icon-light-air021:before {
  content: "\e251"
}

.icon-light-air022:before {
  content: "\e270"
}

.icon-light-air023:before {
  content: "\e277"
}

.icon-light-cat001:before {
  content: "\e04F"
}

.icon-light-cat002:before {
  content: "\e050"
}

.icon-light-cat003:before {
  content: "\e051"
}

.icon-light-cat004:before {
  content: "\e052"
}

.icon-light-cat005:before {
  content: "\e053"
}

.icon-light-cat006:before {
  content: "\e054"
}

.icon-light-cat007:before {
  content: "\e055"
}

.icon-light-cat008:before {
  content: "\e056"
}

.icon-light-cat009:before {
  content: "\e057"
}

.icon-light-cat010:before {
  content: "\e058"
}

.icon-light-cat011:before {
  content: "\e059"
}

.icon-light-cat012:before {
  content: "\e05A"
}

.icon-light-cat013:before {
  content: "\e05B"
}

.icon-light-cat014:before {
  content: "\e05C"
}

.icon-light-cat015:before {
  content: "\e05D"
}

.icon-light-cat016:before {
  content: "\e05E"
}

.icon-light-cat017:before {
  content: "\e24B"
}

.icon-light-cat018:before {
  content: "\e256"
}

.icon-light-cat019:before {
  content: "\e272"
}

.icon-light-cat020:before {
  content: "\e2BF"
}

.icon-light-car001:before {
  content: "\e1F0"
}

.icon-light-car002:before {
  content: "\e1F1"
}

.icon-light-car003:before {
  content: "\e24C"
}

.icon-light-car004:before {
  content: "\e24D"
}

.icon-light-car005:before {
  content: "\e24E"
}

.icon-light-car006:before {
  content: "\e24F"
}

.icon-light-car007:before {
  content: "\e253"
}

.icon-light-car008:before {
  content: "\e287"
}

.icon-light-car009:before {
  content: "\e288"
}

.icon-light-car010:before {
  content: "\e289"
}

.icon-light-car011:before {
  content: "\e28A"
}

.icon-light-car012:before {
  content: "\e29B"
}

.icon-light-car013:before {
  content: "\e29C"
}

.icon-light-car014:before {
  content: "\e2B0"
}

.icon-light-ffp001:before {
  content: "\e1E6"
}

.icon-light-ffp002:before {
  content: "\e1E7"
}

.icon-light-ffp003:before {
  content: "\e1E8"
}

.icon-light-ffp004:before {
  content: "\e1E9"
}

.icon-light-ffp005:before {
  content: "\e1EA"
}

.icon-light-ffp006:before {
  content: "\e1EB"
}

.icon-light-ffp007:before {
  content: "\e1EC"
}

.icon-light-ffp008:before {
  content: "\e1ED"
}

.icon-light-ffp009:before {
  content: "\e1EE"
}

.icon-light-ffp010:before {
  content: "\e1EF"
}

.icon-light-ffp011:before {
  content: "\e25E"
}

.icon-light-ffp012:before {
  content: "\e28F"
}

.icon-light-ffp013:before {
  content: "\e292"
}

.icon-light-ffp014:before {
  content: "\e2BE"
}

.icon-light-ife001:before {
  content: "\e1F2"
}

.icon-light-ife002:before {
  content: "\e1F3"
}

.icon-light-ife003:before {
  content: "\e1F4"
}

.icon-light-ife004:before {
  content: "\e1F5"
}

.icon-light-ife005:before {
  content: "\e1F6"
}

.icon-light-ife006:before {
  content: "\e1F7"
}

.icon-light-ife007:before {
  content: "\e1F8"
}

.icon-light-ife008:before {
  content: "\e1F9"
}

.icon-light-ife009:before {
  content: "\e1FA"
}

.icon-light-ife010:before {
  content: "\e1FB"
}

.icon-light-ife011:before {
  content: "\e1FC"
}

.icon-light-ife012:before {
  content: "\e1FD"
}

.icon-light-ife013:before {
  content: "\e1FE"
}

.icon-light-ife014:before {
  content: "\e1FF"
}

.icon-light-ife015:before {
  content: "\e200"
}

.icon-light-ife016:before {
  content: "\e201"
}

.icon-light-ife017:before {
  content: "\e202"
}

.icon-light-ife018:before {
  content: "\e203"
}

.icon-light-ife019:before {
  content: "\e204"
}

.icon-light-ife020:before {
  content: "\e205"
}

.icon-light-ife021:before {
  content: "\e206"
}

.icon-light-ife022:before {
  content: "\e207"
}

.icon-light-ife023:before {
  content: "\e208"
}

.icon-light-ife024:before {
  content: "\e209"
}

.icon-light-ife025:before {
  content: "\e20A"
}

.icon-light-ife026:before {
  content: "\e20B"
}

.icon-light-ife027:before {
  content: "\e218"
}

.icon-light-ife028:before {
  content: "\e221"
}

.icon-light-ife029:before {
  content: "\e228"
}

.icon-light-ife030:before {
  content: "\e22A"
}

.icon-light-ife031:before {
  content: "\e23B"
}

.icon-light-ife032:before {
  content: "\e23D"
}

.icon-light-ife033:before {
  content: "\e254"
}

.icon-light-ife034:before {
  content: "\e263"
}

.icon-light-ife035:before {
  content: "\e26B"
}

.icon-light-ife036:before {
  content: "\e278"
}

.icon-light-ife037:before {
  content: "\e28B"
}

.icon-light-ife038:before {
  content: "\e28C"
}

.icon-light-ife039:before {
  content: "\e28D"
}

.icon-light-ife040:before {
  content: "\e2B1"
}

.icon-light-int001:before {
  content: "\e191"
}

.icon-light-int002:before {
  content: "\e192"
}

.icon-light-int003:before {
  content: "\e193"
}

.icon-light-int004:before {
  content: "\e194"
}

.icon-light-int005:before {
  content: "\e195"
}

.icon-light-int006:before {
  content: "\e196"
}

.icon-light-int007:before {
  content: "\e197"
}

.icon-light-int008:before {
  content: "\e198"
}

.icon-light-int009:before {
  content: "\e199"
}

.icon-light-int010:before {
  content: "\e19A"
}

.icon-light-int011:before {
  content: "\e19B"
}

.icon-light-int012:before {
  content: "\e19C"
}

.icon-light-int013:before {
  content: "\e19D"
}

.icon-light-int014:before {
  content: "\e19E"
}

.icon-light-int015:before {
  content: "\e19F"
}

.icon-light-int016:before {
  content: "\e1A0"
}

.icon-light-int017:before {
  content: "\e1A1"
}

.icon-light-int018:before {
  content: "\e1A2"
}

.icon-light-int019:before {
  content: "\e1A3"
}

.icon-light-int020:before {
  content: "\e1A4"
}

.icon-light-int021:before {
  content: "\e1A5"
}

.icon-light-int022:before {
  content: "\e1A6"
}

.icon-light-int023:before {
  content: "\e1A7"
}

.icon-light-int024:before {
  content: "\e1A8"
}

.icon-light-int025:before {
  content: "\e1A9"
}

.icon-light-int026:before {
  content: "\e1AA"
}

.icon-light-int027:before {
  content: "\e1AB"
}

.icon-light-int028:before {
  content: "\e1AC"
}

.icon-light-int029:before {
  content: "\e1AD"
}

.icon-light-int030:before {
  content: "\e1AE"
}

.icon-light-int031:before {
  content: "\e1AF"
}

.icon-light-int032:before {
  content: "\e1B0"
}

.icon-light-int033:before {
  content: "\e1B1"
}

.icon-light-int034:before {
  content: "\e1B2"
}

.icon-light-int035:before {
  content: "\e1B3"
}

.icon-light-int036:before {
  content: "\e1B4"
}

.icon-light-int037:before {
  content: "\e1B5"
}

.icon-light-int038:before {
  content: "\e1B6"
}

.icon-light-int039:before {
  content: "\e1B7"
}

.icon-light-int040:before {
  content: "\e1B8"
}

.icon-light-int041:before {
  content: "\e1B9"
}

.icon-light-int042:before {
  content: "\e1BA"
}

.icon-light-int043:before {
  content: "\e1BB"
}

.icon-light-int044:before {
  content: "\e1BC"
}

.icon-light-int045:before {
  content: "\e21A"
}

.icon-light-int046:before {
  content: "\e224"
}

.icon-light-int047:before {
  content: "\e239"
}

.icon-light-int048:before {
  content: "\e243"
}

.icon-light-int049:before {
  content: "\e246"
}

.icon-light-int050:before {
  content: "\e261"
}

.icon-light-int051:before {
  content: "\e264"
}

.icon-light-int052:before {
  content: "\e2A6"
}

.icon-light-int053:before {
  content: "\e258"
}

.icon-light-int054:before {
  content: "\e25A"
}

.icon-light-int055:before {
  content: "\e2B2"
}

.icon-light-int056:before {
  content: "\e2B4"
}

.icon-light-int057:before {
  content: "\e2B5"
}

.icon-light-int058:before {
  content: "\e2B6"
}

.icon-light-int059:before {
  content: "\e2B3"
}

.icon-light-int060:before {
  content: "\e2B7"
}

.icon-light-peo001:before {
  content: "\e05F"
}

.icon-light-peo002:before {
  content: "\e060"
}

.icon-light-peo003:before {
  content: "\e061"
}

.icon-light-peo004:before {
  content: "\e062"
}

.icon-light-peo005:before {
  content: "\e063"
}

.icon-light-peo006:before {
  content: "\e064"
}

.icon-light-peo007:before {
  content: "\e065"
}

.icon-light-peo008:before {
  content: "\e066"
}

.icon-light-peo009:before {
  content: "\e067"
}

.icon-light-peo010:before {
  content: "\e068"
}

.icon-light-peo011:before {
  content: "\e069"
}

.icon-light-peo012:before {
  content: "\e06A"
}

.icon-light-peo013:before {
  content: "\e06B"
}

.icon-light-peo014:before {
  content: "\e06C"
}

.icon-light-peo015:before {
  content: "\e06D"
}

.icon-light-peo016:before {
  content: "\e06E"
}

.icon-light-peo017:before {
  content: "\e06F"
}

.icon-light-peo018:before {
  content: "\e070"
}

.icon-light-peo019:before {
  content: "\e071"
}

.icon-light-peo020:before {
  content: "\e072"
}

.icon-light-peo021:before {
  content: "\e073"
}

.icon-light-peo022:before {
  content: "\e074"
}

.icon-light-peo023:before {
  content: "\e075"
}

.icon-light-peo024:before {
  content: "\e076"
}

.icon-light-peo025:before {
  content: "\e077"
}

.icon-light-peo026:before {
  content: "\e078"
}

.icon-light-peo027:before {
  content: "\e079"
}

.icon-light-peo028:before {
  content: "\e07A"
}

.icon-light-peo029:before {
  content: "\e07B"
}

.icon-light-peo030:before {
  content: "\e07C"
}

.icon-light-peo031:before {
  content: "\e07D"
}

.icon-light-peo032:before {
  content: "\e07E"
}

.icon-light-peo033:before {
  content: "\e07F"
}

.icon-light-peo034:before {
  content: "\e213"
}

.icon-light-peo035:before {
  content: "\e225"
}

.icon-light-peo036:before {
  content: "\e226"
}

.icon-light-peo037:before {
  content: "\e244"
}

.icon-light-peo038:before {
  content: "\e247"
}

.icon-light-peo039:before {
  content: "\e248"
}

.icon-light-peo040:before {
  content: "\e25F"
}

.icon-light-peo041:before {
  content: "\e290"
}

.icon-light-peo042:before {
  content: "\e293"
}

.icon-light-peo043:before {
  content: "\e265"
}

.icon-light-peo044:before {
  content: "\e267"
}

.icon-light-peo045:before {
  content: "\e269"
}

.icon-light-peo046:before {
  content: "\e26C"
}

.icon-light-peo047:before {
  content: "\e26E"
}

.icon-light-peo048:before {
  content: "\e299"
}

.icon-light-peo049:before {
  content: "\e2A7"
}

.icon-light-peo050:before {
  content: "\e2A9"
}

.icon-light-peo051:before {
  content: "\e2B8"
}

.icon-light-peo052:before {
  content: "\e2B9"
}

.icon-light-peo053:before {
  content: "\e2BB"
}

.icon-light-peo054:before {
  content: "\e2BC"
}

.icon-light-sec001:before {
  content: "\e000"
}

.icon-light-sec002:before {
  content: "\e001"
}

.icon-light-sec003:before {
  content: "\e002"
}

.icon-light-sec004:before {
  content: "\e003"
}

.icon-light-sec005:before {
  content: "\e004"
}

.icon-light-sec006:before {
  content: "\e005"
}

.icon-light-sec007:before {
  content: "\e006"
}

.icon-light-sec008:before {
  content: "\e007"
}

.icon-light-sec009:before {
  content: "\e008"
}

.icon-light-sec010:before {
  content: "\e009"
}

.icon-light-sec011:before {
  content: "\e00A"
}

.icon-light-sec012:before {
  content: "\e00B"
}

.icon-light-sec013:before {
  content: "\e00C"
}

.icon-light-sec014:before {
  content: "\e00D"
}

.icon-light-sec015:before {
  content: "\e00E"
}

.icon-light-sec016:before {
  content: "\e00F"
}

.icon-light-sec017:before {
  content: "\e010"
}

.icon-light-sec018:before {
  content: "\e011"
}

.icon-light-sec019:before {
  content: "\e012"
}

.icon-light-sec020:before {
  content: "\e013"
}

.icon-light-sec021:before {
  content: "\e014"
}

.icon-light-sec022:before {
  content: "\e015"
}

.icon-light-sec023:before {
  content: "\e016"
}

.icon-light-sec024:before {
  content: "\e017"
}

.icon-light-sec025:before {
  content: "\e018"
}

.icon-light-sec026:before {
  content: "\e019"
}

.icon-light-sec027:before {
  content: "\e01A"
}

.icon-light-sec028:before {
  content: "\e01B"
}

.icon-light-sec029:before {
  content: "\e01C"
}

.icon-light-sec030:before {
  content: "\e01D"
}

.icon-light-sec031:before {
  content: "\e01E"
}

.icon-light-sec032:before {
  content: "\e01F"
}

.icon-light-sec033:before {
  content: "\e020"
}

.icon-light-sec034:before {
  content: "\e021"
}

.icon-light-sec035:before {
  content: "\e022"
}

.icon-light-sec036:before {
  content: "\e023"
}

.icon-light-sec037:before {
  content: "\e024"
}

.icon-light-sec038:before {
  content: "\e025"
}

.icon-light-sec039:before {
  content: "\e026"
}

.icon-light-sec040:before {
  content: "\e027"
}

.icon-light-sec041:before {
  content: "\e028"
}

.icon-light-sec042:before {
  content: "\e029"
}

.icon-light-sec043:before {
  content: "\e02A"
}

.icon-light-sec044:before {
  content: "\e02B"
}

.icon-light-sec045:before {
  content: "\e02C"
}

.icon-light-sec046:before {
  content: "\e02D"
}

.icon-light-sec047:before {
  content: "\e02E"
}

.icon-light-sec048:before {
  content: "\e02F"
}

.icon-light-sec049:before {
  content: "\e030"
}

.icon-light-sec050:before {
  content: "\e031"
}

.icon-light-sec051:before {
  content: "\e032"
}

.icon-light-sec052:before {
  content: "\e033"
}

.icon-light-sec053:before {
  content: "\e034"
}

.icon-light-sec054:before {
  content: "\e035"
}

.icon-light-sec055:before {
  content: "\e036"
}

.icon-light-sec056:before {
  content: "\e037"
}

.icon-light-sec057:before {
  content: "\e038"
}

.icon-light-sec058:before {
  content: "\e039"
}

.icon-light-sec059:before {
  content: "\e03A"
}

.icon-light-sec060:before {
  content: "\e03B"
}

.icon-light-sec061:before {
  content: "\e03C"
}

.icon-light-sec062:before {
  content: "\e03D"
}

.icon-light-sec063:before {
  content: "\e03E"
}

.icon-light-sec064:before {
  content: "\e03F"
}

.icon-light-sec065:before {
  content: "\e040"
}

.icon-light-sec066:before {
  content: "\e041"
}

.icon-light-sec067:before {
  content: "\e042"
}

.icon-light-sec068:before {
  content: "\e21B"
}

.icon-light-sec069:before {
  content: "\e21C"
}

.icon-light-sec070:before {
  content: "\e273"
}

.icon-light-sec071:before {
  content: "\e274"
}

.icon-light-sec072:before {
  content: "\e296"
}

.icon-light-sec073:before {
  content: "\e297"
}

.icon-light-sec074:before {
  content: "\e298"
}

.icon-light-sec075:before {
  content: "\e29A"
}

.icon-light-sec076:before {
  content: "\e2B3"
}

.icon-light-sig001:before {
  content: "\e080"
}

.icon-light-sig002:before {
  content: "\e081"
}

.icon-light-sig003:before {
  content: "\e082"
}

.icon-light-sig004:before {
  content: "\e083"
}

.icon-light-sig005:before {
  content: "\e084"
}

.icon-light-sig006:before {
  content: "\e085"
}

.icon-light-sig007:before {
  content: "\e086"
}

.icon-light-sig008:before {
  content: "\e087"
}

.icon-light-sig009:before {
  content: "\e088"
}

.icon-light-sig010:before {
  content: "\e089"
}

.icon-light-sig011:before {
  content: "\e08A"
}

.icon-light-sig012:before {
  content: "\e08B"
}

.icon-light-sig013:before {
  content: "\e08C"
}

.icon-light-sig014:before {
  content: "\e08D"
}

.icon-light-sig015:before {
  content: "\e08E"
}

.icon-light-sig016:before {
  content: "\e08F"
}

.icon-light-sig017:before {
  content: "\e090"
}

.icon-light-sig018:before {
  content: "\e091"
}

.icon-light-sig019:before {
  content: "\e092"
}

.icon-light-sig020:before {
  content: "\e093"
}

.icon-light-sig021:before {
  content: "\e094"
}

.icon-light-sig022:before {
  content: "\e095"
}

.icon-light-sig023:before {
  content: "\e096"
}

.icon-light-sig024:before {
  content: "\e097"
}

.icon-light-sig025:before {
  content: "\e098"
}

.icon-light-sig026:before {
  content: "\e099"
}

.icon-light-sig027:before {
  content: "\e09A"
}

.icon-light-sig028:before {
  content: "\e09B"
}

.icon-light-sig029:before {
  content: "\e09C"
}

.icon-light-sig030:before {
  content: "\e09D"
}

.icon-light-sig031:before {
  content: "\e09E"
}

.icon-light-sig032:before {
  content: "\e09F"
}

.icon-light-sig033:before {
  content: "\e0A0"
}

.icon-light-sig034:before {
  content: "\e0A1"
}

.icon-light-sig035:before {
  content: "\e0A2"
}

.icon-light-sig036:before {
  content: "\e0A3"
}

.icon-light-sig037:before {
  content: "\e0A4"
}

.icon-light-sig038:before {
  content: "\e0A5"
}

.icon-light-sig039:before {
  content: "\e0A6"
}

.icon-light-sig040:before {
  content: "\e0A7"
}

.icon-light-sig041:before {
  content: "\e0A8"
}

.icon-light-sig042:before {
  content: "\e0A9"
}

.icon-light-sig043:before {
  content: "\e0AA"
}

.icon-light-sig044:before {
  content: "\e0AB"
}

.icon-light-sig045:before {
  content: "\e0AC"
}

.icon-light-sig046:before {
  content: "\e0AD"
}

.icon-light-sig047:before {
  content: "\e0AE"
}

.icon-light-sig048:before {
  content: "\e0AF"
}

.icon-light-sig049:before {
  content: "\e0B0"
}

.icon-light-sig050:before {
  content: "\e0B1"
}

.icon-light-sig051:before {
  content: "\e0B2"
}

.icon-light-sig052:before {
  content: "\e0B3"
}

.icon-light-sig053:before {
  content: "\e0B4"
}

.icon-light-sig054:before {
  content: "\e0B5"
}

.icon-light-sig055:before {
  content: "\e0B6"
}

.icon-light-sig056:before {
  content: "\e0B7"
}

.icon-light-sig057:before {
  content: "\e0B8"
}

.icon-light-sig058:before {
  content: "\e0B9"
}

.icon-light-sig059:before {
  content: "\e0BA"
}

.icon-light-sig060:before {
  content: "\e0BB"
}

.icon-light-sig061:before {
  content: "\e0BC"
}

.icon-light-sig062:before {
  content: "\e0BD"
}

.icon-light-sig063:before {
  content: "\e0BE"
}

.icon-light-sig064:before {
  content: "\e0BF"
}

.icon-light-sig065:before {
  content: "\e0C0"
}

.icon-light-sig066:before {
  content: "\e0C1"
}

.icon-light-sig067:before {
  content: "\e0C2"
}

.icon-light-sig068:before {
  content: "\e0C3"
}

.icon-light-sig069:before {
  content: "\e0C4"
}

.icon-light-sig070:before {
  content: "\e0C5"
}

.icon-light-sig071:before {
  content: "\e0C6"
}

.icon-light-sig072:before {
  content: "\e0C7"
}

.icon-light-sig073:before {
  content: "\e0C8"
}

.icon-light-sig074:before {
  content: "\e0C9"
}

.icon-light-sig075:before {
  content: "\e0CA"
}

.icon-light-sig076:before {
  content: "\e0CB"
}

.icon-light-sig077:before {
  content: "\e0CC"
}

.icon-light-sig078:before {
  content: "\e0CD"
}

.icon-light-sig079:before {
  content: "\e0CE"
}

.icon-light-sig080:before {
  content: "\e0CF"
}

.icon-light-sig081:before {
  content: "\e0D0"
}

.icon-light-sig082:before {
  content: "\e0D1"
}

.icon-light-sig083:before {
  content: "\e0D2"
}

.icon-light-sig084:before {
  content: "\e0D3"
}

.icon-light-sig085:before {
  content: "\e0D4"
}

.icon-light-sig086:before {
  content: "\e0D5"
}

.icon-light-sig087:before {
  content: "\e0D6"
}

.icon-light-sig088:before {
  content: "\e0D7"
}

.icon-light-sig089:before {
  content: "\e0D8"
}

.icon-light-sig090:before {
  content: "\e0D9"
}

.icon-light-sig091:before {
  content: "\e0DA"
}

.icon-light-sig092:before {
  content: "\e0DB"
}

.icon-light-sig093:before {
  content: "\e0DC"
}

.icon-light-sig094:before {
  content: "\e0DD"
}

.icon-light-sig095:before {
  content: "\e0DE"
}

.icon-light-sig096:before {
  content: "\e0DF"
}

.icon-light-sig097:before {
  content: "\e0E0"
}

.icon-light-sig098:before {
  content: "\e0E1"
}

.icon-light-sig099:before {
  content: "\e0E2"
}

.icon-light-sig100:before {
  content: "\e0E3"
}

.icon-light-sig101:before {
  content: "\e0E4"
}

.icon-light-sig102:before {
  content: "\e0E5"
}

.icon-light-sig103:before {
  content: "\e0E6"
}

.icon-light-sig104:before {
  content: "\e0E7"
}

.icon-light-sig105:before {
  content: "\e0E8"
}

.icon-light-sig106:before {
  content: "\e0E9"
}

.icon-light-sig107:before {
  content: "\e0EA"
}

.icon-light-sig108:before {
  content: "\e0EB"
}

.icon-light-sig109:before {
  content: "\e0EC"
}

.icon-light-sig110:before {
  content: "\e0ED"
}

.icon-light-sig111:before {
  content: "\e0EE"
}

.icon-light-sig112:before {
  content: "\e0EF"
}

.icon-light-sig113:before {
  content: "\e0F0"
}

.icon-light-sig114:before {
  content: "\e0F1"
}

.icon-light-sig115:before {
  content: "\e0F2"
}

.icon-light-sig116:before {
  content: "\e0F3"
}

.icon-light-sig117:before {
  content: "\e0F4"
}

.icon-light-sig118:before {
  content: "\e0F5"
}

.icon-light-sig119:before {
  content: "\e0F6"
}

.icon-light-sig120:before {
  content: "\e0F7"
}

.icon-light-sig121:before {
  content: "\e0F8"
}

.icon-light-sig122:before {
  content: "\e0F9"
}

.icon-light-sig123:before {
  content: "\e0FA"
}

.icon-light-sig124:before {
  content: "\e0FB"
}

.icon-light-sig125:before {
  content: "\e0FC"
}

.icon-light-sig126:before {
  content: "\e0FD"
}

.icon-light-sig127:before {
  content: "\e0FE"
}

.icon-light-sig128:before {
  content: "\e0FF"
}

.icon-light-sig129:before {
  content: "\e100"
}

.icon-light-sig130:before {
  content: "\e101"
}

.icon-light-sig131:before {
  content: "\e219"
}

.icon-light-sig132:before {
  content: "\e21F"
}

.icon-light-sig133:before {
  content: "\e227"
}

.icon-light-sig134:before {
  content: "\e23A"
}

.icon-light-sig135:before {
  content: "\e240"
}

.icon-light-sig136:before {
  content: "\e241"
}

.icon-light-sig137:before {
  content: "\e249"
}

.icon-light-sig138:before {
  content: "\e24A"
}

.icon-light-sig139:before {
  content: "\e252"
}

.icon-light-sig140:before {
  content: "\e257"
}

.icon-light-sig141:before {
  content: "\e25C"
}

.icon-light-sig142:before {
  content: "\e260"
}

.icon-light-sig143:before {
  content: "\e291"
}

.icon-light-sig144:before {
  content: "\e294"
}

.icon-light-sig145:before {
  content: "\e262"
}

.icon-light-sig146:before {
  content: "\e266"
}

.icon-light-sig147:before {
  content: "\e268"
}

.icon-light-sig148:before {
  content: "\e26A"
}

.icon-light-sig149:before {
  content: "\e26D"
}

.icon-light-sig150:before {
  content: "\e26E"
}

.icon-light-sig151:before {
  content: "\e271"
}

.icon-light-sig152:before {
  content: "\e276"
}

.icon-light-sig153:before {
  content: "\e285"
}

.icon-light-sig154:before {
  content: "\e28E"
}

.icon-light-sig155:before {
  content: "\e29B"
}

.icon-light-sig156:before {
  content: "\e29C"
}

.icon-light-sig157:before {
  content: "\e29D"
}

.icon-light-sig158:before {
  content: "\e29E"
}

.icon-light-sig159:before {
  content: "\e29F"
}

.icon-light-sig160:before {
  content: "\e2A0"
}

.icon-light-sig161:before {
  content: "\e2A1"
}

.icon-light-sig162:before {
  content: "\e2A2"
}

.icon-light-sig163:before {
  content: "\e2A3"
}

.icon-light-sig164:before {
  content: "\e2A4"
}

.icon-light-sig165:before {
  content: "\e2A5"
}

.icon-light-sig166:before {
  content: "\e2A8"
}

.icon-light-sig167:before {
  content: "\e2AA"
}

.icon-light-sig168:before {
  content: "\e2B8"
}

.icon-light-sig169:before {
  content: "\e2B9"
}

.icon-light-sig170:before {
  content: "\e2BB"
}

.icon-light-sig171:before {
  content: "\e2BC"
}

.icon-light-sig172:before {
  content: "\e2BA"
}

.icon-light-sig173:before {
  content: "\e2BD"
}

.icon-light-tra001:before {
  content: "\e158"
}

.icon-light-tra002:before {
  content: "\e159"
}

.icon-light-tra003:before {
  content: "\e15A"
}

.icon-light-tra004:before {
  content: "\e15B"
}

.icon-light-tra005:before {
  content: "\e15C"
}

.icon-light-tra006:before {
  content: "\e15D"
}

.icon-light-tra007:before {
  content: "\e15E"
}

.icon-light-tra008:before {
  content: "\e15F"
}

.icon-light-tra009:before {
  content: "\e160"
}

.icon-light-tra010:before {
  content: "\e161"
}

.icon-light-tra011:before {
  content: "\e162"
}

.icon-light-tra012:before {
  content: "\e163"
}

.icon-light-tra013:before {
  content: "\e164"
}

.icon-light-tra014:before {
  content: "\e165"
}

.icon-light-tra015:before {
  content: "\e166"
}

.icon-light-tra016:before {
  content: "\e167"
}

.icon-light-tra017:before {
  content: "\e168"
}

.icon-light-tra018:before {
  content: "\e169"
}

.icon-light-tra019:before {
  content: "\e16A"
}

.icon-light-tra020:before {
  content: "\e16B"
}

.icon-light-tra021:before {
  content: "\e16C"
}

.icon-light-tra022:before {
  content: "\e16D"
}

.icon-light-tra023:before {
  content: "\e16E"
}

.icon-light-tra024:before {
  content: "\e16F"
}

.icon-light-tra025:before {
  content: "\e170"
}

.icon-light-tra026:before {
  content: "\e171"
}

.icon-light-tra027:before {
  content: "\e172"
}

.icon-light-tra028:before {
  content: "\e173"
}

.icon-light-tra029:before {
  content: "\e174"
}

.icon-light-tra030:before {
  content: "\e175"
}

.icon-light-tra031:before {
  content: "\e176"
}

.icon-light-tra032:before {
  content: "\e177"
}

.icon-light-tra033:before {
  content: "\e178"
}

.icon-light-tra034:before {
  content: "\e179"
}

.icon-light-tra035:before {
  content: "\e17A"
}

.icon-light-tra036:before {
  content: "\e17B"
}

.icon-light-tra037:before {
  content: "\e17C"
}

.icon-light-tra038:before {
  content: "\e17D"
}

.icon-light-tra039:before {
  content: "\e17E"
}

.icon-light-tra040:before {
  content: "\e17F"
}

.icon-light-tra041:before {
  content: "\e180"
}

.icon-light-tra042:before {
  content: "\e181"
}

.icon-light-tra043:before {
  content: "\e182"
}

.icon-light-tra044:before {
  content: "\e183"
}

.icon-light-tra045:before {
  content: "\e184"
}

.icon-light-tra046:before {
  content: "\e185"
}

.icon-light-tra047:before {
  content: "\e186"
}

.icon-light-tra048:before {
  content: "\e187"
}

.icon-light-tra049:before {
  content: "\e188"
}

.icon-light-tra050:before {
  content: "\e189"
}

.icon-light-tra051:before {
  content: "\e18A"
}

.icon-light-tra052:before {
  content: "\e18B"
}

.icon-light-tra053:before {
  content: "\e18C"
}

.icon-light-tra054:before {
  content: "\e18D"
}

.icon-light-tra055:before {
  content: "\e18E"
}

.icon-light-tra056:before {
  content: "\e18F"
}

.icon-light-tra057:before {
  content: "\e190"
}

.icon-light-tra058:before {
  content: "\e20C"
}

.icon-light-tra059:before {
  content: "\e20D"
}

.icon-light-tra060:before {
  content: "\e20F"
}

.icon-light-tra061:before {
  content: "\e21E"
}

.icon-light-tra062:before {
  content: "\e220"
}

.icon-light-tra063:before {
  content: "\e222"
}

.icon-light-tra064:before {
  content: "\e22C"
}

.icon-light-tra065:before {
  content: "\e22D"
}

.icon-light-tra066:before {
  content: "\e22E"
}

.icon-light-tra067:before {
  content: "\e22F"
}

.icon-light-tra068:before {
  content: "\e230"
}

.icon-light-tra069:before {
  content: "\e231"
}

.icon-light-tra070:before {
  content: "\e232"
}

.icon-light-tra071:before {
  content: "\e233"
}

.icon-light-tra072:before {
  content: "\e234"
}

.icon-light-tra073:before {
  content: "\e235"
}

.icon-light-tra074:before {
  content: "\e236"
}

.icon-light-tra075:before {
  content: "\e237"
}

.icon-light-tra076:before {
  content: "\e238"
}

.icon-light-tra077:before {
  content: "\e25D"
}

.icon-light-tra078:before {
  content: "\e279"
}

.icon-light-tra079:before {
  content: "\e27A"
}

.icon-light-tra080:before {
  content: "\e27B"
}

.icon-light-tra081:before {
  content: "\e27C"
}

.icon-light-tra082:before {
  content: "\e27D"
}

.icon-light-tra083:before {
  content: "\e27E"
}

.icon-light-tra084:before {
  content: "\e27F"
}

.icon-light-tra085:before {
  content: "\e280"
}

.icon-light-tra086:before {
  content: "\e281"
}

.icon-light-tra087:before {
  content: "\e282"
}

.icon-light-tra088:before {
  content: "\e283"
}

.icon-light-tra089:before {
  content: "\e284"
}

.icon-light-tra090:before {
  content: "\e286"
}

.icon-light-tra091:before {
  content: "\e295"
}

.icon-light-tra092:before {
  content: "\e2AB"
}

.icon-light-tra093:before {
  content: "\e2AC"
}

.icon-light-tra094:before {
  content: "\e2AD"
}

.icon-light-tra095:before {
  content: "\e2AF"
}

.icon-light-tra096:before {
  content: "\e2B2"
}

.icon-light-tra097:before {
  content: "\e2B5"
}

.icon-light-uid001:before {
  content: "\e102"
}

.icon-light-uid002:before {
  content: "\e103"
}

.icon-light-uid003:before {
  content: "\e104"
}

.icon-light-uid004:before {
  content: "\e105"
}

.icon-light-uid005:before {
  content: "\e106"
}

.icon-light-uid006:before {
  content: "\e107"
}

.icon-light-uid007:before {
  content: "\e108"
}

.icon-light-uid008:before {
  content: "\e109"
}

.icon-light-uid009:before {
  content: "\e10A"
}

.icon-light-uid010:before {
  content: "\e10B"
}

.icon-light-uid011:before {
  content: "\e10C"
}

.icon-light-uid012:before {
  content: "\e10D"
}

.icon-light-uid013:before {
  content: "\e10E"
}

.icon-light-uid014:before {
  content: "\e10F"
}

.icon-light-uid015:before {
  content: "\e110"
}

.icon-light-uid016:before {
  content: "\e111"
}

.icon-light-uid017:before {
  content: "\e112"
}

.icon-light-uid018:before {
  content: "\e113"
}

.icon-light-uid019:before {
  content: "\e114"
}

.icon-light-uid020:before {
  content: "\e115"
}

.icon-light-uid021:before {
  content: "\e116"
}

.icon-light-uid022:before {
  content: "\e117"
}

.icon-light-uid023:before {
  content: "\e118"
}

.icon-light-uid024:before {
  content: "\e119"
}

.icon-light-uid025:before {
  content: "\e11A"
}

.icon-light-uid026:before {
  content: "\e11B"
}

.icon-light-uid027:before {
  content: "\e11C"
}

.icon-light-uid028:before {
  content: "\e11D"
}

.icon-light-uid029:before {
  content: "\e11E"
}

.icon-light-uid030:before {
  content: "\e11F"
}

.icon-light-uid031:before {
  content: "\e120"
}

.icon-light-uid032:before {
  content: "\e121"
}

.icon-light-uid033:before {
  content: "\e122"
}

.icon-light-uid034:before {
  content: "\e123"
}

.icon-light-uid035:before {
  content: "\e124"
}

.icon-light-uid036:before {
  content: "\e125"
}

.icon-light-uid037:before {
  content: "\e126"
}

.icon-light-uid038:before {
  content: "\e127"
}

.icon-light-uid039:before {
  content: "\e128"
}

.icon-light-uid040:before {
  content: "\e129"
}

.icon-light-uid041:before {
  content: "\e12A"
}

.icon-light-uid042:before {
  content: "\e12B"
}

.icon-light-uid043:before {
  content: "\e12C"
}

.icon-light-uid044:before {
  content: "\e12D"
}

.icon-light-uid045:before {
  content: "\e12E"
}

.icon-light-uid046:before {
  content: "\e12F"
}

.icon-light-uid047:before {
  content: "\e130"
}

.icon-light-uid048:before {
  content: "\e131"
}

.icon-light-uid049:before {
  content: "\e132"
}

.icon-light-uid050:before {
  content: "\e133"
}

.icon-light-uid051:before {
  content: "\e134"
}

.icon-light-uid052:before {
  content: "\e135"
}

.icon-light-uid053:before {
  content: "\e136"
}

.icon-light-uid054:before {
  content: "\e137"
}

.icon-light-uid055:before {
  content: "\e138"
}

.icon-light-uid056:before {
  content: "\e139"
}

.icon-light-uid057:before {
  content: "\e13A"
}

.icon-light-uid058:before {
  content: "\e13B"
}

.icon-light-uid059:before {
  content: "\e13C"
}

.icon-light-uid060:before {
  content: "\e13D"
}

.icon-light-uid061:before {
  content: "\e13E"
}

.icon-light-uid062:before {
  content: "\e13F"
}

.icon-light-uid063:before {
  content: "\e140"
}

.icon-light-uid064:before {
  content: "\e141"
}

.icon-light-uid065:before {
  content: "\e142"
}

.icon-light-uid066:before {
  content: "\e143"
}

.icon-light-uid067:before {
  content: "\e144"
}

.icon-light-uid068:before {
  content: "\e145"
}

.icon-light-uid069:before {
  content: "\e146"
}

.icon-light-uid070:before {
  content: "\e147"
}

.icon-light-uid071:before {
  content: "\e148"
}

.icon-light-uid072:before {
  content: "\e149"
}

.icon-light-uid073:before {
  content: "\e14A"
}

.icon-light-uid074:before {
  content: "\e14B"
}

.icon-light-uid075:before {
  content: "\e14C"
}

.icon-light-uid076:before {
  content: "\e14D"
}

.icon-light-uid077:before {
  content: "\e14E"
}

.icon-light-uid078:before {
  content: "\e14F"
}

.icon-light-uid079:before {
  content: "\e150"
}

.icon-light-uid080:before {
  content: "\e151"
}

.icon-light-uid081:before {
  content: "\e152"
}

.icon-light-uid082:before {
  content: "\e153"
}

.icon-light-uid083:before {
  content: "\e154"
}

.icon-light-uid084:before {
  content: "\e155"
}

.icon-light-uid085:before {
  content: "\e156"
}

.icon-light-uid086:before {
  content: "\e157"
}

.icon-light-uid087:before {
  content: "\e20E"
}

.icon-light-uid088:before {
  content: "\e217"
}

.icon-light-uid089:before {
  content: "\e223"
}

.icon-light-uid090:before {
  content: "\e229"
}

.icon-light-uid091:before {
  content: "\e22B"
}

.icon-light-uid092:before {
  content: "\e23C"
}

.icon-light-uid093:before {
  content: "\e23E"
}

.icon-light-uid094:before {
  content: "\e250"
}

.icon-light-uid095:before {
  content: "\e255"
}

.icon-light-uid096:before {
  content: "\e259"
}

.icon-light-uid097:before {
  content: "\e25B"
}

.icon-light-uid098:before {
  content: "\e2AE"
}

.icon-light-uid099:before {
  content: "\e2BD"
}

.icon-light-wea001:before {
  content: "\e1BD"
}

.icon-light-wea002:before {
  content: "\e1BE"
}

.icon-light-wea003:before {
  content: "\e1BF"
}

.icon-light-wea004:before {
  content: "\e1C0"
}

.icon-light-wea005:before {
  content: "\e1C1"
}

.icon-light-wea006:before {
  content: "\e1C2"
}

.icon-light-wea007:before {
  content: "\e1C3"
}

.icon-light-wea008:before {
  content: "\e1C4"
}

.icon-light-wea009:before {
  content: "\e1C5"
}

.icon-light-wea010:before {
  content: "\e1C6"
}

.icon-light-wea011:before {
  content: "\e1C7"
}

.icon-light-wea012:before {
  content: "\e1C8"
}

.icon-light-wea013:before {
  content: "\e1C9"
}

.icon-light-wea014:before {
  content: "\e1CA"
}

.icon-light-wea015:before {
  content: "\e1CB"
}

.icon-light-wea016:before {
  content: "\e1CC"
}

.icon-light-wea017:before {
  content: "\e1CD"
}

.icon-light-wea018:before {
  content: "\e1CE"
}

.icon-light-wea019:before {
  content: "\e1CF"
}

.icon-light-wea020:before {
  content: "\e1D0"
}

.icon-light-wea021:before {
  content: "\e1D1"
}

.icon-light-wea022:before {
  content: "\e1D2"
}

.icon-light-wea023:before {
  content: "\e1D3"
}

.icon-light-wea024:before {
  content: "\e1D4"
}

.icon-light-wea025:before {
  content: "\e1D5"
}

.icon-light-wea026:before {
  content: "\e1D6"
}

.icon-light-wea027:before {
  content: "\e1D7"
}

.icon-light-wea028:before {
  content: "\e1D8"
}

.icon-light-wea029:before {
  content: "\e1D9"
}

.icon-light-wea030:before {
  content: "\e1DA"
}

.icon-light-wea031:before {
  content: "\e1DB"
}

.icon-light-wea032:before {
  content: "\e1DC"
}

.icon-light-wea033:before {
  content: "\e1DD"
}

.icon-light-wea034:before {
  content: "\e1DE"
}

.icon-light-wea035:before {
  content: "\e1DF"
}

.icon-light-wea036:before {
  content: "\e1E0"
}

.icon-light-wea037:before {
  content: "\e1E1"
}

.icon-light-wea038:before {
  content: "\e1E2"
}

.icon-light-wea039:before {
  content: "\e1E3"
}

.icon-light-wea040:before {
  content: "\e1E4"
}

.icon-light-wea041:before {
  content: "\e1E5"
}

@font-face {
  font-family: latam-icons-regular;
  font-style: normal;
  font-weight: 400;
  src: url(assets/fonts/latam-icons/eot/Latam_Icons-Regular.eot#iefix) format('embedded-opentype');
}

@font-face {
  font-family: latam-icons-regular;
  font-style: normal;
  font-weight: 400;
  src: url(assets/fonts/latam-icons/woff2/Latam_Icons-Regular.woff2) format('woff2'), url(assets/fonts/latam-icons/woff/Latam_Icons-Regular.woff) format('woff'), url(assets/fonts/latam-icons/ttf/Latam_Icons-Regular.ttf) format('truetype');
}

[class*=icon-regular], [class^=icon-regular] {
  font-family: latam-icons-regular;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
}

.icon-regular-air001:before {
  content: "\e043"
}

.icon-regular-air002:before {
  content: "\e044"
}

.icon-regular-air003:before {
  content: "\e045"
}

.icon-regular-air004:before {
  content: "\e046"
}

.icon-regular-air005:before {
  content: "\e047"
}

.icon-regular-air006:before {
  content: "\e048"
}

.icon-regular-air007:before {
  content: "\e049"
}

.icon-regular-air008:before {
  content: "\e04A"
}

.icon-regular-air009:before {
  content: "\e04B"
}

.icon-regular-air010:before {
  content: "\e04C"
}

.icon-regular-air011:before {
  content: "\e04D"
}

.icon-regular-air012:before {
  content: "\e04E"
}

.icon-regular-air013:before {
  content: "\e210"
}

.icon-regular-air014:before {
  content: "\e275"
}

.icon-regular-air015:before {
  content: "\e215"
}

.icon-regular-air016:before {
  content: "\e216"
}

.icon-regular-air017:before {
  content: "\e21D"
}

.icon-regular-air018:before {
  content: "\e23F"
}

.icon-regular-air019:before {
  content: "\e242"
}

.icon-regular-air020:before {
  content: "\e245"
}

.icon-regular-air021:before {
  content: "\e251"
}

.icon-regular-air022:before {
  content: "\e270"
}

.icon-regular-air023:before {
  content: "\e277"
}

.icon-regular-cat001:before {
  content: "\e04F"
}

.icon-regular-cat002:before {
  content: "\e050"
}

.icon-regular-cat003:before {
  content: "\e051"
}

.icon-regular-cat004:before {
  content: "\e052"
}

.icon-regular-cat005:before {
  content: "\e053"
}

.icon-regular-cat006:before {
  content: "\e054"
}

.icon-regular-cat007:before {
  content: "\e055"
}

.icon-regular-cat008:before {
  content: "\e056"
}

.icon-regular-cat009:before {
  content: "\e057"
}

.icon-regular-cat010:before {
  content: "\e058"
}

.icon-regular-cat011:before {
  content: "\e059"
}

.icon-regular-cat012:before {
  content: "\e05A"
}

.icon-regular-cat013:before {
  content: "\e05B"
}

.icon-regular-cat014:before {
  content: "\e05C"
}

.icon-regular-cat015:before {
  content: "\e05D"
}

.icon-regular-cat016:before {
  content: "\e05E"
}

.icon-regular-cat017:before {
  content: "\e24B"
}

.icon-regular-cat018:before {
  content: "\e256"
}

.icon-regular-cat019:before {
  content: "\e272"
}

.icon-regular-cat020:before {
  content: "\e2BF"
}

.icon-regular-car001:before {
  content: "\e1F0"
}

.icon-regular-car002:before {
  content: "\e1F1"
}

.icon-regular-car003:before {
  content: "\e24C"
}

.icon-regular-car004:before {
  content: "\e24D"
}

.icon-regular-car005:before {
  content: "\e24E"
}

.icon-regular-car006:before {
  content: "\e24F"
}

.icon-regular-car007:before {
  content: "\e253"
}

.icon-regular-car008:before {
  content: "\e287"
}

.icon-regular-car009:before {
  content: "\e288"
}

.icon-regular-car010:before {
  content: "\e289"
}

.icon-regular-car011:before {
  content: "\e28A"
}

.icon-regular-car012:before {
  content: "\e29B"
}

.icon-regular-car013:before {
  content: "\e29C"
}

.icon-regular-car014:before {
  content: "\e2B0"
}

.icon-regular-ffp001:before {
  content: "\e1E6"
}

.icon-regular-ffp002:before {
  content: "\e1E7"
}

.icon-regular-ffp003:before {
  content: "\e1E8"
}

.icon-regular-ffp004:before {
  content: "\e1E9"
}

.icon-regular-ffp005:before {
  content: "\e1EA"
}

.icon-regular-ffp006:before {
  content: "\e1EB"
}

.icon-regular-ffp007:before {
  content: "\e1EC"
}

.icon-regular-ffp008:before {
  content: "\e1ED"
}

.icon-regular-ffp009:before {
  content: "\e1EE"
}

.icon-regular-ffp010:before {
  content: "\e1EF"
}

.icon-regular-ffp011:before {
  content: "\e25E"
}

.icon-regular-ffp012:before {
  content: "\e28F"
}

.icon-regular-ffp013:before {
  content: "\e292"
}

.icon-regular-ffp014:before {
  content: "\e2BE"
}

.icon-regular-ife001:before {
  content: "\e1F2"
}

.icon-regular-ife002:before {
  content: "\e1F3"
}

.icon-regular-ife003:before {
  content: "\e1F4"
}

.icon-regular-ife004:before {
  content: "\e1F5"
}

.icon-regular-ife005:before {
  content: "\e1F6"
}

.icon-regular-ife006:before {
  content: "\e1F7"
}

.icon-regular-ife007:before {
  content: "\e1F8"
}

.icon-regular-ife008:before {
  content: "\e1F9"
}

.icon-regular-ife009:before {
  content: "\e1FA"
}

.icon-regular-ife010:before {
  content: "\e1FB"
}

.icon-regular-ife011:before {
  content: "\e1FC"
}

.icon-regular-ife012:before {
  content: "\e1FD"
}

.icon-regular-ife013:before {
  content: "\e1FE"
}

.icon-regular-ife014:before {
  content: "\e1FF"
}

.icon-regular-ife015:before {
  content: "\e200"
}

.icon-regular-ife016:before {
  content: "\e201"
}

.icon-regular-ife017:before {
  content: "\e202"
}

.icon-regular-ife018:before {
  content: "\e203"
}

.icon-regular-ife019:before {
  content: "\e204"
}

.icon-regular-ife020:before {
  content: "\e205"
}

.icon-regular-ife021:before {
  content: "\e206"
}

.icon-regular-ife022:before {
  content: "\e207"
}

.icon-regular-ife023:before {
  content: "\e208"
}

.icon-regular-ife024:before {
  content: "\e209"
}

.icon-regular-ife025:before {
  content: "\e20A"
}

.icon-regular-ife026:before {
  content: "\e20B"
}

.icon-regular-ife027:before {
  content: "\e218"
}

.icon-regular-ife028:before {
  content: "\e221"
}

.icon-regular-ife029:before {
  content: "\e228"
}

.icon-regular-ife030:before {
  content: "\e22A"
}

.icon-regular-ife031:before {
  content: "\e23B"
}

.icon-regular-ife032:before {
  content: "\e23D"
}

.icon-regular-ife033:before {
  content: "\e254"
}

.icon-regular-ife034:before {
  content: "\e263"
}

.icon-regular-ife035:before {
  content: "\e26B"
}

.icon-regular-ife036:before {
  content: "\e278"
}

.icon-regular-ife037:before {
  content: "\e28B"
}

.icon-regular-ife038:before {
  content: "\e28C"
}

.icon-regular-ife039:before {
  content: "\e28D"
}

.icon-regular-ife040:before {
  content: "\e2B1"
}

.icon-regular-int001:before {
  content: "\e191"
}

.icon-regular-int002:before {
  content: "\e192"
}

.icon-regular-int003:before {
  content: "\e193"
}

.icon-regular-int004:before {
  content: "\e194"
}

.icon-regular-int005:before {
  content: "\e195"
}

.icon-regular-int006:before {
  content: "\e196"
}

.icon-regular-int007:before {
  content: "\e197"
}

.icon-regular-int008:before {
  content: "\e198"
}

.icon-regular-int009:before {
  content: "\e199"
}

.icon-regular-int010:before {
  content: "\e19A"
}

.icon-regular-int011:before {
  content: "\e19B"
}

.icon-regular-int012:before {
  content: "\e19C"
}

.icon-regular-int013:before {
  content: "\e19D"
}

.icon-regular-int014:before {
  content: "\e19E"
}

.icon-regular-int015:before {
  content: "\e19F"
}

.icon-regular-int016:before {
  content: "\e1A0"
}

.icon-regular-int017:before {
  content: "\e1A1"
}

.icon-regular-int018:before {
  content: "\e1A2"
}

.icon-regular-int019:before {
  content: "\e1A3"
}

.icon-regular-int020:before {
  content: "\e1A4"
}

.icon-regular-int021:before {
  content: "\e1A5"
}

.icon-regular-int022:before {
  content: "\e1A6"
}

.icon-regular-int023:before {
  content: "\e1A7"
}

.icon-regular-int024:before {
  content: "\e1A8"
}

.icon-regular-int025:before {
  content: "\e1A9"
}

.icon-regular-int026:before {
  content: "\e1AA"
}

.icon-regular-int027:before {
  content: "\e1AB"
}

.icon-regular-int028:before {
  content: "\e1AC"
}

.icon-regular-int029:before {
  content: "\e1AD"
}

.icon-regular-int030:before {
  content: "\e1AE"
}

.icon-regular-int031:before {
  content: "\e1AF"
}

.icon-regular-int032:before {
  content: "\e1B0"
}

.icon-regular-int033:before {
  content: "\e1B1"
}

.icon-regular-int034:before {
  content: "\e1B2"
}

.icon-regular-int035:before {
  content: "\e1B3"
}

.icon-regular-int036:before {
  content: "\e1B4"
}

.icon-regular-int037:before {
  content: "\e1B5"
}

.icon-regular-int038:before {
  content: "\e1B6"
}

.icon-regular-int039:before {
  content: "\e1B7"
}

.icon-regular-int040:before {
  content: "\e1B8"
}

.icon-regular-int041:before {
  content: "\e1B9"
}

.icon-regular-int042:before {
  content: "\e1BA"
}

.icon-regular-int043:before {
  content: "\e1BB"
}

.icon-regular-int044:before {
  content: "\e1BC"
}

.icon-regular-int045:before {
  content: "\e21A"
}

.icon-regular-int046:before {
  content: "\e224"
}

.icon-regular-int047:before {
  content: "\e239"
}

.icon-regular-int048:before {
  content: "\e243"
}

.icon-regular-int049:before {
  content: "\e246"
}

.icon-regular-int050:before {
  content: "\e261"
}

.icon-regular-int051:before {
  content: "\e264"
}

.icon-regular-int052:before {
  content: "\e2A6"
}

.icon-regular-int053:before {
  content: "\e258"
}

.icon-regular-int054:before {
  content: "\e25A"
}

.icon-regular-int055:before {
  content: "\e2B2"
}

.icon-regular-int056:before {
  content: "\e2B4"
}

.icon-regular-int057:before {
  content: "\e2B5"
}

.icon-regular-int058:before {
  content: "\e2B6"
}

.icon-regular-int059:before {
  content: "\e2B3"
}

.icon-regular-int060:before {
  content: "\e2B7"
}

.icon-regular-peo001:before {
  content: "\e05F"
}

.icon-regular-peo002:before {
  content: "\e060"
}

.icon-regular-peo003:before {
  content: "\e061"
}

.icon-regular-peo004:before {
  content: "\e062"
}

.icon-regular-peo005:before {
  content: "\e063"
}

.icon-regular-peo006:before {
  content: "\e064"
}

.icon-regular-peo007:before {
  content: "\e065"
}

.icon-regular-peo008:before {
  content: "\e066"
}

.icon-regular-peo009:before {
  content: "\e067"
}

.icon-regular-peo010:before {
  content: "\e068"
}

.icon-regular-peo011:before {
  content: "\e069"
}

.icon-regular-peo012:before {
  content: "\e06A"
}

.icon-regular-peo013:before {
  content: "\e06B"
}

.icon-regular-peo014:before {
  content: "\e06C"
}

.icon-regular-peo015:before {
  content: "\e06D"
}

.icon-regular-peo016:before {
  content: "\e06E"
}

.icon-regular-peo017:before {
  content: "\e06F"
}

.icon-regular-peo018:before {
  content: "\e070"
}

.icon-regular-peo019:before {
  content: "\e071"
}

.icon-regular-peo020:before {
  content: "\e072"
}

.icon-regular-peo021:before {
  content: "\e073"
}

.icon-regular-peo022:before {
  content: "\e074"
}

.icon-regular-peo023:before {
  content: "\e075"
}

.icon-regular-peo024:before {
  content: "\e076"
}

.icon-regular-peo025:before {
  content: "\e077"
}

.icon-regular-peo026:before {
  content: "\e078"
}

.icon-regular-peo027:before {
  content: "\e079"
}

.icon-regular-peo028:before {
  content: "\e07A"
}

.icon-regular-peo029:before {
  content: "\e07B"
}

.icon-regular-peo030:before {
  content: "\e07C"
}

.icon-regular-peo031:before {
  content: "\e07D"
}

.icon-regular-peo032:before {
  content: "\e07E"
}

.icon-regular-peo033:before {
  content: "\e211"
}

.icon-regular-peo034:before {
  content: "\e213"
}

.icon-regular-peo035:before {
  content: "\e225"
}

.icon-regular-peo036:before {
  content: "\e226"
}

.icon-regular-peo037:before {
  content: "\e244"
}

.icon-regular-peo038:before {
  content: "\e247"
}

.icon-regular-peo039:before {
  content: "\e248"
}

.icon-regular-peo040:before {
  content: "\e25F"
}

.icon-regular-peo041:before {
  content: "\e290"
}

.icon-regular-peo042:before {
  content: "\e293"
}

.icon-regular-peo043:before {
  content: "\e265"
}

.icon-regular-peo044:before {
  content: "\e267"
}

.icon-regular-peo045:before {
  content: "\e269"
}

.icon-regular-peo046:before {
  content: "\e26C"
}

.icon-regular-peo047:before {
  content: "\e26E"
}

.icon-regular-peo048:before {
  content: "\e299"
}

.icon-regular-peo049:before {
  content: "\e2A7"
}

.icon-regular-peo050:before {
  content: "\e2A9"
}

.icon-regular-peo051:before {
  content: "\e2B8"
}

.icon-regular-peo052:before {
  content: "\e2B9"
}

.icon-regular-peo053:before {
  content: "\e2BB"
}

.icon-regular-peo054:before {
  content: "\e2BC"
}

.icon-regular-sec001:before {
  content: "\e000"
}

.icon-regular-sec002:before {
  content: "\e001"
}

.icon-regular-sec003:before {
  content: "\e002"
}

.icon-regular-sec004:before {
  content: "\e003"
}

.icon-regular-sec005:before {
  content: "\e004"
}

.icon-regular-sec006:before {
  content: "\e005"
}

.icon-regular-sec007:before {
  content: "\e006"
}

.icon-regular-sec008:before {
  content: "\e007"
}

.icon-regular-sec009:before {
  content: "\e008"
}

.icon-regular-sec010:before {
  content: "\e009"
}

.icon-regular-sec011:before {
  content: "\e00A"
}

.icon-regular-sec012:before {
  content: "\e00B"
}

.icon-regular-sec013:before {
  content: "\e00C"
}

.icon-regular-sec014:before {
  content: "\e00D"
}

.icon-regular-sec015:before {
  content: "\e00E"
}

.icon-regular-sec016:before {
  content: "\e00F"
}

.icon-regular-sec017:before {
  content: "\e010"
}

.icon-regular-sec018:before {
  content: "\e011"
}

.icon-regular-sec019:before {
  content: "\e012"
}

.icon-regular-sec020:before {
  content: "\e013"
}

.icon-regular-sec021:before {
  content: "\e014"
}

.icon-regular-sec022:before {
  content: "\e015"
}

.icon-regular-sec023:before {
  content: "\e016"
}

.icon-regular-sec024:before {
  content: "\e017"
}

.icon-regular-sec025:before {
  content: "\e018"
}

.icon-regular-sec026:before {
  content: "\e019"
}

.icon-regular-sec027:before {
  content: "\e01A"
}

.icon-regular-sec028:before {
  content: "\e01B"
}

.icon-regular-sec029:before {
  content: "\e01C"
}

.icon-regular-sec030:before {
  content: "\e01D"
}

.icon-regular-sec031:before {
  content: "\e01E"
}

.icon-regular-sec032:before {
  content: "\e01F"
}

.icon-regular-sec033:before {
  content: "\e020"
}

.icon-regular-sec034:before {
  content: "\e021"
}

.icon-regular-sec035:before {
  content: "\e022"
}

.icon-regular-sec036:before {
  content: "\e023"
}

.icon-regular-sec037:before {
  content: "\e024"
}

.icon-regular-sec038:before {
  content: "\e025"
}

.icon-regular-sec039:before {
  content: "\e026"
}

.icon-regular-sec040:before {
  content: "\e027"
}

.icon-regular-sec041:before {
  content: "\e028"
}

.icon-regular-sec042:before {
  content: "\e029"
}

.icon-regular-sec043:before {
  content: "\e02A"
}

.icon-regular-sec044:before {
  content: "\e02B"
}

.icon-regular-sec045:before {
  content: "\e02C"
}

.icon-regular-sec046:before {
  content: "\e02D"
}

.icon-regular-sec047:before {
  content: "\e02E"
}

.icon-regular-sec048:before {
  content: "\e02F"
}

.icon-regular-sec049:before {
  content: "\e030"
}

.icon-regular-sec050:before {
  content: "\e031"
}

.icon-regular-sec051:before {
  content: "\e032"
}

.icon-regular-sec052:before {
  content: "\e033"
}

.icon-regular-sec053:before {
  content: "\e034"
}

.icon-regular-sec054:before {
  content: "\e035"
}

.icon-regular-sec055:before {
  content: "\e036"
}

.icon-regular-sec056:before {
  content: "\e037"
}

.icon-regular-sec057:before {
  content: "\e038"
}

.icon-regular-sec058:before {
  content: "\e039"
}

.icon-regular-sec059:before {
  content: "\e03A"
}

.icon-regular-sec060:before {
  content: "\e03B"
}

.icon-regular-sec061:before {
  content: "\e03C"
}

.icon-regular-sec062:before {
  content: "\e03D"
}

.icon-regular-sec063:before {
  content: "\e03E"
}

.icon-regular-sec064:before {
  content: "\e03F"
}

.icon-regular-sec065:before {
  content: "\e040"
}

.icon-regular-sec066:before {
  content: "\e041"
}

.icon-regular-sec067:before {
  content: "\e042"
}

.icon-regular-sec068:before {
  content: "\e21B"
}

.icon-regular-sec069:before {
  content: "\e21C"
}

.icon-regular-sec070:before {
  content: "\e273"
}

.icon-regular-sec071:before {
  content: "\e274"
}

.icon-regular-sec072:before {
  content: "\e296"
}

.icon-regular-sec073:before {
  content: "\e297"
}

.icon-regular-sec074:before {
  content: "\e298"
}

.icon-regular-sec075:before {
  content: "\e29A"
}

.icon-regular-sec076:before {
  content: "\e2B3"
}

.icon-regular-sig001:before {
  content: "\e080"
}

.icon-regular-sig002:before {
  content: "\e081"
}

.icon-regular-sig003:before {
  content: "\e082"
}

.icon-regular-sig004:before {
  content: "\e083"
}

.icon-regular-sig005:before {
  content: "\e084"
}

.icon-regular-sig006:before {
  content: "\e085"
}

.icon-regular-sig007:before {
  content: "\e086"
}

.icon-regular-sig008:before {
  content: "\e087"
}

.icon-regular-sig009:before {
  content: "\e088"
}

.icon-regular-sig010:before {
  content: "\e089"
}

.icon-regular-sig011:before {
  content: "\e08A"
}

.icon-regular-sig012:before {
  content: "\e08B"
}

.icon-regular-sig013:before {
  content: "\e08C"
}

.icon-regular-sig014:before {
  content: "\e08D"
}

.icon-regular-sig015:before {
  content: "\e08E"
}

.icon-regular-sig016:before {
  content: "\e08F"
}

.icon-regular-sig017:before {
  content: "\e090"
}

.icon-regular-sig018:before {
  content: "\e091"
}

.icon-regular-sig019:before {
  content: "\e092"
}

.icon-regular-sig020:before {
  content: "\e093"
}

.icon-regular-sig021:before {
  content: "\e094"
}

.icon-regular-sig022:before {
  content: "\e095"
}

.icon-regular-sig023:before {
  content: "\e096"
}

.icon-regular-sig024:before {
  content: "\e097"
}

.icon-regular-sig025:before {
  content: "\e098"
}

.icon-regular-sig026:before {
  content: "\e099"
}

.icon-regular-sig027:before {
  content: "\e09A"
}

.icon-regular-sig028:before {
  content: "\e09B"
}

.icon-regular-sig029:before {
  content: "\e09C"
}

.icon-regular-sig030:before {
  content: "\e09D"
}

.icon-regular-sig031:before {
  content: "\e09E"
}

.icon-regular-sig032:before {
  content: "\e09F"
}

.icon-regular-sig033:before {
  content: "\e0A0"
}

.icon-regular-sig034:before {
  content: "\e0A1"
}

.icon-regular-sig035:before {
  content: "\e0A2"
}

.icon-regular-sig036:before {
  content: "\e0A3"
}

.icon-regular-sig037:before {
  content: "\e0A4"
}

.icon-regular-sig038:before {
  content: "\e0A5"
}

.icon-regular-sig039:before {
  content: "\e0A6"
}

.icon-regular-sig040:before {
  content: "\e0A7"
}

.icon-regular-sig041:before {
  content: "\e0A8"
}

.icon-regular-sig042:before {
  content: "\e0A9"
}

.icon-regular-sig043:before {
  content: "\e0AA"
}

.icon-regular-sig044:before {
  content: "\e0AB"
}

.icon-regular-sig045:before {
  content: "\e0AC"
}

.icon-regular-sig046:before {
  content: "\e0AD"
}

.icon-regular-sig047:before {
  content: "\e0AE"
}

.icon-regular-sig048:before {
  content: "\e0AF"
}

.icon-regular-sig049:before {
  content: "\e0B0"
}

.icon-regular-sig050:before {
  content: "\e0B1"
}

.icon-regular-sig051:before {
  content: "\e0B2"
}

.icon-regular-sig052:before {
  content: "\e0B3"
}

.icon-regular-sig053:before {
  content: "\e0B4"
}

.icon-regular-sig054:before {
  content: "\e0B5"
}

.icon-regular-sig055:before {
  content: "\e0B6"
}

.icon-regular-sig056:before {
  content: "\e0B7"
}

.icon-regular-sig057:before {
  content: "\e0B8"
}

.icon-regular-sig058:before {
  content: "\e0B9"
}

.icon-regular-sig059:before {
  content: "\e0BA"
}

.icon-regular-sig060:before {
  content: "\e0BB"
}

.icon-regular-sig061:before {
  content: "\e0BC"
}

.icon-regular-sig062:before {
  content: "\e0BD"
}

.icon-regular-sig063:before {
  content: "\e0BE"
}

.icon-regular-sig064:before {
  content: "\e0BF"
}

.icon-regular-sig065:before {
  content: "\e0C0"
}

.icon-regular-sig066:before {
  content: "\e0C1"
}

.icon-regular-sig067:before {
  content: "\e0C2"
}

.icon-regular-sig068:before {
  content: "\e0C3"
}

.icon-regular-sig069:before {
  content: "\e0C4"
}

.icon-regular-sig070:before {
  content: "\e0C5"
}

.icon-regular-sig071:before {
  content: "\e0C6"
}

.icon-regular-sig072:before {
  content: "\e0C7"
}

.icon-regular-sig073:before {
  content: "\e0C8"
}

.icon-regular-sig074:before {
  content: "\e0C9"
}

.icon-regular-sig075:before {
  content: "\e0CA"
}

.icon-regular-sig076:before {
  content: "\e0CB"
}

.icon-regular-sig077:before {
  content: "\e0CC"
}

.icon-regular-sig078:before {
  content: "\e0CD"
}

.icon-regular-sig079:before {
  content: "\e0CE"
}

.icon-regular-sig080:before {
  content: "\e0CF"
}

.icon-regular-sig081:before {
  content: "\e0D0"
}

.icon-regular-sig082:before {
  content: "\e0D1"
}

.icon-regular-sig083:before {
  content: "\e0D2"
}

.icon-regular-sig084:before {
  content: "\e0D3"
}

.icon-regular-sig085:before {
  content: "\e0D4"
}

.icon-regular-sig086:before {
  content: "\e0D5"
}

.icon-regular-sig087:before {
  content: "\e0D6"
}

.icon-regular-sig088:before {
  content: "\e0D7"
}

.icon-regular-sig089:before {
  content: "\e0D8"
}

.icon-regular-sig090:before {
  content: "\e0D9"
}

.icon-regular-sig091:before {
  content: "\e0DA"
}

.icon-regular-sig092:before {
  content: "\e0DB"
}

.icon-regular-sig093:before {
  content: "\e0DC"
}

.icon-regular-sig094:before {
  content: "\e0DD"
}

.icon-regular-sig095:before {
  content: "\e0DE"
}

.icon-regular-sig096:before {
  content: "\e0DF"
}

.icon-regular-sig097:before {
  content: "\e0E0"
}

.icon-regular-sig098:before {
  content: "\e0E1"
}

.icon-regular-sig099:before {
  content: "\e0E2"
}

.icon-regular-sig100:before {
  content: "\e0E3"
}

.icon-regular-sig101:before {
  content: "\e0E4"
}

.icon-regular-sig102:before {
  content: "\e0E5"
}

.icon-regular-sig103:before {
  content: "\e0E6"
}

.icon-regular-sig104:before {
  content: "\e0E7"
}

.icon-regular-sig105:before {
  content: "\e0E8"
}

.icon-regular-sig106:before {
  content: "\e0E9"
}

.icon-regular-sig107:before {
  content: "\e0EA"
}

.icon-regular-sig108:before {
  content: "\e0EB"
}

.icon-regular-sig109:before {
  content: "\e0EC"
}

.icon-regular-sig110:before {
  content: "\e0ED"
}

.icon-regular-sig111:before {
  content: "\e0EE"
}

.icon-regular-sig112:before {
  content: "\e0EF"
}

.icon-regular-sig113:before {
  content: "\e0F0"
}

.icon-regular-sig114:before {
  content: "\e0F1"
}

.icon-regular-sig115:before {
  content: "\e0F2"
}

.icon-regular-sig116:before {
  content: "\e0F3"
}

.icon-regular-sig117:before {
  content: "\e0F4"
}

.icon-regular-sig118:before {
  content: "\e0F5"
}

.icon-regular-sig119:before {
  content: "\e0F6"
}

.icon-regular-sig120:before {
  content: "\e0F7"
}

.icon-regular-sig121:before {
  content: "\e0F8"
}

.icon-regular-sig122:before {
  content: "\e0F9"
}

.icon-regular-sig123:before {
  content: "\e0FA"
}

.icon-regular-sig124:before {
  content: "\e0FB"
}

.icon-regular-sig125:before {
  content: "\e0FC"
}

.icon-regular-sig126:before {
  content: "\e0FD"
}

.icon-regular-sig127:before {
  content: "\e0FE"
}

.icon-regular-sig128:before {
  content: "\e0FF"
}

.icon-regular-sig129:before {
  content: "\e212"
}

.icon-regular-sig130:before {
  content: "\e214"
}

.icon-regular-sig131:before {
  content: "\e219"
}

.icon-regular-sig132:before {
  content: "\e21F"
}

.icon-regular-sig133:before {
  content: "\e227"
}

.icon-regular-sig134:before {
  content: "\e23A"
}

.icon-regular-sig135:before {
  content: "\e240"
}

.icon-regular-sig136:before {
  content: "\e241"
}

.icon-regular-sig137:before {
  content: "\e249"
}

.icon-regular-sig138:before {
  content: "\e24A"
}

.icon-regular-sig139:before {
  content: "\e252"
}

.icon-regular-sig140:before {
  content: "\e257"
}

.icon-regular-sig141:before {
  content: "\e25C"
}

.icon-regular-sig142:before {
  content: "\e260"
}

.icon-regular-sig143:before {
  content: "\e291"
}

.icon-regular-sig144:before {
  content: "\e294"
}

.icon-regular-sig145:before {
  content: "\e262"
}

.icon-regular-sig146:before {
  content: "\e266"
}

.icon-regular-sig147:before {
  content: "\e268"
}

.icon-regular-sig148:before {
  content: "\e26A"
}

.icon-regular-sig149:before {
  content: "\e26D"
}

.icon-regular-sig150:before {
  content: "\e26E"
}

.icon-regular-sig151:before {
  content: "\e271"
}

.icon-regular-sig152:before {
  content: "\e276"
}

.icon-regular-sig153:before {
  content: "\e285"
}

.icon-regular-sig154:before {
  content: "\e28E"
}

.icon-regular-sig155:before {
  content: "\e29B"
}

.icon-regular-sig156:before {
  content: "\e29C"
}

.icon-regular-sig157:before {
  content: "\e29D"
}

.icon-regular-sig158:before {
  content: "\e29E"
}

.icon-regular-sig159:before {
  content: "\e29F"
}

.icon-regular-sig160:before {
  content: "\e2A0"
}

.icon-regular-sig161:before {
  content: "\e2A1"
}

.icon-regular-sig162:before {
  content: "\e2A2"
}

.icon-regular-sig163:before {
  content: "\e2A3"
}

.icon-regular-sig164:before {
  content: "\e2A4"
}

.icon-regular-sig165:before {
  content: "\e2A5"
}

.icon-regular-sig166:before {
  content: "\e2A8"
}

.icon-regular-sig167:before {
  content: "\e2AA"
}

.icon-regular-sig168:before {
  content: "\e2B8"
}

.icon-regular-sig169:before {
  content: "\e2B9"
}

.icon-regular-sig170:before {
  content: "\e2BB"
}

.icon-regular-sig171:before {
  content: "\e2BC"
}

.icon-regular-sig172:before {
  content: "\e2BA"
}

.icon-regular-sig173:before {
  content: "\e2BD"
}

.icon-regular-tra001:before {
  content: "\e158"
}

.icon-regular-tra002:before {
  content: "\e159"
}

.icon-regular-tra003:before {
  content: "\e15A"
}

.icon-regular-tra004:before {
  content: "\e15B"
}

.icon-regular-tra005:before {
  content: "\e15C"
}

.icon-regular-tra006:before {
  content: "\e15D"
}

.icon-regular-tra007:before {
  content: "\e15E"
}

.icon-regular-tra008:before {
  content: "\e15F"
}

.icon-regular-tra009:before {
  content: "\e160"
}

.icon-regular-tra010:before {
  content: "\e161"
}

.icon-regular-tra011:before {
  content: "\e162"
}

.icon-regular-tra012:before {
  content: "\e163"
}

.icon-regular-tra013:before {
  content: "\e164"
}

.icon-regular-tra014:before {
  content: "\e165"
}

.icon-regular-tra015:before {
  content: "\e166"
}

.icon-regular-tra016:before {
  content: "\e167"
}

.icon-regular-tra017:before {
  content: "\e168"
}

.icon-regular-tra018:before {
  content: "\e169"
}

.icon-regular-tra019:before {
  content: "\e16A"
}

.icon-regular-tra020:before {
  content: "\e16B"
}

.icon-regular-tra021:before {
  content: "\e16C"
}

.icon-regular-tra022:before {
  content: "\e16D"
}

.icon-regular-tra023:before {
  content: "\e16E"
}

.icon-regular-tra024:before {
  content: "\e16F"
}

.icon-regular-tra025:before {
  content: "\e170"
}

.icon-regular-tra026:before {
  content: "\e171"
}

.icon-regular-tra027:before {
  content: "\e172"
}

.icon-regular-tra028:before {
  content: "\e173"
}

.icon-regular-tra029:before {
  content: "\e174"
}

.icon-regular-tra030:before {
  content: "\e175"
}

.icon-regular-tra031:before {
  content: "\e176"
}

.icon-regular-tra032:before {
  content: "\e177"
}

.icon-regular-tra033:before {
  content: "\e178"
}

.icon-regular-tra034:before {
  content: "\e179"
}

.icon-regular-tra035:before {
  content: "\e17A"
}

.icon-regular-tra036:before {
  content: "\e17B"
}

.icon-regular-tra037:before {
  content: "\e17C"
}

.icon-regular-tra038:before {
  content: "\e17D"
}

.icon-regular-tra039:before {
  content: "\e17E"
}

.icon-regular-tra040:before {
  content: "\e17F"
}

.icon-regular-tra041:before {
  content: "\e180"
}

.icon-regular-tra042:before {
  content: "\e181"
}

.icon-regular-tra043:before {
  content: "\e182"
}

.icon-regular-tra044:before {
  content: "\e183"
}

.icon-regular-tra045:before {
  content: "\e184"
}

.icon-regular-tra046:before {
  content: "\e185"
}

.icon-regular-tra047:before {
  content: "\e186"
}

.icon-regular-tra048:before {
  content: "\e187"
}

.icon-regular-tra049:before {
  content: "\e188"
}

.icon-regular-tra050:before {
  content: "\e189"
}

.icon-regular-tra051:before {
  content: "\e18A"
}

.icon-regular-tra052:before {
  content: "\e18B"
}

.icon-regular-tra053:before {
  content: "\e18C"
}

.icon-regular-tra054:before {
  content: "\e18D"
}

.icon-regular-tra055:before {
  content: "\e18E"
}

.icon-regular-tra056:before {
  content: "\e18F"
}

.icon-regular-tra057:before {
  content: "\e190"
}

.icon-regular-tra058:before {
  content: "\e20C"
}

.icon-regular-tra059:before {
  content: "\e20D"
}

.icon-regular-tra060:before {
  content: "\e20F"
}

.icon-regular-tra061:before {
  content: "\e21E"
}

.icon-regular-tra062:before {
  content: "\e220"
}

.icon-regular-tra063:before {
  content: "\e222"
}

.icon-regular-tra064:before {
  content: "\e22C"
}

.icon-regular-tra065:before {
  content: "\e22D"
}

.icon-regular-tra066:before {
  content: "\e22E"
}

.icon-regular-tra067:before {
  content: "\e22F"
}

.icon-regular-tra068:before {
  content: "\e230"
}

.icon-regular-tra069:before {
  content: "\e231"
}

.icon-regular-tra070:before {
  content: "\e232"
}

.icon-regular-tra071:before {
  content: "\e233"
}

.icon-regular-tra072:before {
  content: "\e234"
}

.icon-regular-tra073:before {
  content: "\e235"
}

.icon-regular-tra074:before {
  content: "\e236"
}

.icon-regular-tra075:before {
  content: "\e237"
}

.icon-regular-tra076:before {
  content: "\e238"
}

.icon-regular-tra077:before {
  content: "\e25D"
}

.icon-regular-tra078:before {
  content: "\e279"
}

.icon-regular-tra079:before {
  content: "\e27A"
}

.icon-regular-tra080:before {
  content: "\e27B"
}

.icon-regular-tra081:before {
  content: "\e27C"
}

.icon-regular-tra082:before {
  content: "\e27D"
}

.icon-regular-tra083:before {
  content: "\e27E"
}

.icon-regular-tra084:before {
  content: "\e27F"
}

.icon-regular-tra085:before {
  content: "\e280"
}

.icon-regular-tra086:before {
  content: "\e281"
}

.icon-regular-tra087:before {
  content: "\e282"
}

.icon-regular-tra088:before {
  content: "\e283"
}

.icon-regular-tra089:before {
  content: "\e284"
}

.icon-regular-tra090:before {
  content: "\e286"
}

.icon-regular-tra091:before {
  content: "\e295"
}

.icon-regular-tra092:before {
  content: "\e2AB"
}

.icon-regular-tra093:before {
  content: "\e2AC"
}

.icon-regular-tra094:before {
  content: "\e2AD"
}

.icon-regular-tra095:before {
  content: "\e2AF"
}

.icon-regular-tra096:before {
  content: "\e2B2"
}

.icon-regular-tra097:before {
  content: "\e2B5"
}

.icon-regular-uid001:before {
  content: "\e102"
}

.icon-regular-uid002:before {
  content: "\e103"
}

.icon-regular-uid003:before {
  content: "\e104"
}

.icon-regular-uid004:before {
  content: "\e105"
}

.icon-regular-uid005:before {
  content: "\e106"
}

.icon-regular-uid006:before {
  content: "\e107"
}

.icon-regular-uid007:before {
  content: "\e108"
}

.icon-regular-uid008:before {
  content: "\e109"
}

.icon-regular-uid009:before {
  content: "\e10A"
}

.icon-regular-uid010:before {
  content: "\e10B"
}

.icon-regular-uid011:before {
  content: "\e10C"
}

.icon-regular-uid012:before {
  content: "\e10D"
}

.icon-regular-uid013:before {
  content: "\e10E"
}

.icon-regular-uid014:before {
  content: "\e10F"
}

.icon-regular-uid015:before {
  content: "\e110"
}

.icon-regular-uid016:before {
  content: "\e111"
}

.icon-regular-uid017:before {
  content: "\e112"
}

.icon-regular-uid018:before {
  content: "\e113"
}

.icon-regular-uid019:before {
  content: "\e114"
}

.icon-regular-uid020:before {
  content: "\e115"
}

.icon-regular-uid021:before {
  content: "\e116"
}

.icon-regular-uid022:before {
  content: "\e117"
}

.icon-regular-uid023:before {
  content: "\e118"
}

.icon-regular-uid024:before {
  content: "\e119"
}

.icon-regular-uid025:before {
  content: "\e11A"
}

.icon-regular-uid026:before {
  content: "\e11B"
}

.icon-regular-uid027:before {
  content: "\e11C"
}

.icon-regular-uid028:before {
  content: "\e11D"
}

.icon-regular-uid029:before {
  content: "\e11E"
}

.icon-regular-uid030:before {
  content: "\e11F"
}

.icon-regular-uid031:before {
  content: "\e120"
}

.icon-regular-uid032:before {
  content: "\e121"
}

.icon-regular-uid033:before {
  content: "\e122"
}

.icon-regular-uid034:before {
  content: "\e123"
}

.icon-regular-uid035:before {
  content: "\e124"
}

.icon-regular-uid036:before {
  content: "\e125"
}

.icon-regular-uid037:before {
  content: "\e126"
}

.icon-regular-uid038:before {
  content: "\e127"
}

.icon-regular-uid039:before {
  content: "\e128"
}

.icon-regular-uid040:before {
  content: "\e129"
}

.icon-regular-uid041:before {
  content: "\e12A"
}

.icon-regular-uid042:before {
  content: "\e12B"
}

.icon-regular-uid043:before {
  content: "\e12C"
}

.icon-regular-uid044:before {
  content: "\e12D"
}

.icon-regular-uid045:before {
  content: "\e12E"
}

.icon-regular-uid046:before {
  content: "\e12F"
}

.icon-regular-uid047:before {
  content: "\e130"
}

.icon-regular-uid048:before {
  content: "\e131"
}

.icon-regular-uid049:before {
  content: "\e132"
}

.icon-regular-uid050:before {
  content: "\e133"
}

.icon-regular-uid051:before {
  content: "\e134"
}

.icon-regular-uid052:before {
  content: "\e135"
}

.icon-regular-uid053:before {
  content: "\e136"
}

.icon-regular-uid054:before {
  content: "\e137"
}

.icon-regular-uid055:before {
  content: "\e138"
}

.icon-regular-uid056:before {
  content: "\e139"
}

.icon-regular-uid057:before {
  content: "\e13A"
}

.icon-regular-uid058:before {
  content: "\e13B"
}

.icon-regular-uid059:before {
  content: "\e13C"
}

.icon-regular-uid060:before {
  content: "\e13D"
}

.icon-regular-uid061:before {
  content: "\e13E"
}

.icon-regular-uid062:before {
  content: "\e13F"
}

.icon-regular-uid063:before {
  content: "\e140"
}

.icon-regular-uid064:before {
  content: "\e141"
}

.icon-regular-uid065:before {
  content: "\e142"
}

.icon-regular-uid066:before {
  content: "\e143"
}

.icon-regular-uid067:before {
  content: "\e144"
}

.icon-regular-uid068:before {
  content: "\e145"
}

.icon-regular-uid069:before {
  content: "\e146"
}

.icon-regular-uid070:before {
  content: "\e147"
}

.icon-regular-uid071:before {
  content: "\e148"
}

.icon-regular-uid072:before {
  content: "\e149"
}

.icon-regular-uid073:before {
  content: "\e14A"
}

.icon-regular-uid074:before {
  content: "\e14B"
}

.icon-regular-uid075:before {
  content: "\e14C"
}

.icon-regular-uid076:before {
  content: "\e14D"
}

.icon-regular-uid077:before {
  content: "\e14E"
}

.icon-regular-uid078:before {
  content: "\e14F"
}

.icon-regular-uid079:before {
  content: "\e150"
}

.icon-regular-uid080:before {
  content: "\e151"
}

.icon-regular-uid081:before {
  content: "\e152"
}

.icon-regular-uid082:before {
  content: "\e153"
}

.icon-regular-uid083:before {
  content: "\e154"
}

.icon-regular-uid084:before {
  content: "\e155"
}

.icon-regular-uid085:before {
  content: "\e156"
}

.icon-regular-uid086:before {
  content: "\e157"
}

.icon-regular-uid087:before {
  content: "\e20E"
}

.icon-regular-uid088:before {
  content: "\e217"
}

.icon-regular-uid089:before {
  content: "\e223"
}

.icon-regular-uid090:before {
  content: "\e229"
}

.icon-regular-uid091:before {
  content: "\e22B"
}

.icon-regular-uid092:before {
  content: "\e23C"
}

.icon-regular-uid093:before {
  content: "\e23E"
}

.icon-regular-uid094:before {
  content: "\e250"
}

.icon-regular-uid095:before {
  content: "\e255"
}

.icon-regular-uid096:before {
  content: "\e259"
}

.icon-regular-uid097:before {
  content: "\e25B"
}

.icon-regular-uid098:before {
  content: "\e2AE"
}

.icon-regular-uid099:before {
  content: "\e2BD"
}

.icon-regular-wea001:before {
  content: "\e1BD"
}

.icon-regular-wea002:before {
  content: "\e1BE"
}

.icon-regular-wea003:before {
  content: "\e1BF"
}

.icon-regular-wea004:before {
  content: "\e1C0"
}

.icon-regular-wea005:before {
  content: "\e1C1"
}

.icon-regular-wea006:before {
  content: "\e1C2"
}

.icon-regular-wea007:before {
  content: "\e1C3"
}

.icon-regular-wea008:before {
  content: "\e1C4"
}

.icon-regular-wea009:before {
  content: "\e1C5"
}

.icon-regular-wea010:before {
  content: "\e1C6"
}

.icon-regular-wea011:before {
  content: "\e1C7"
}

.icon-regular-wea012:before {
  content: "\e1C8"
}

.icon-regular-wea013:before {
  content: "\e1C9"
}

.icon-regular-wea014:before {
  content: "\e1CA"
}

.icon-regular-wea015:before {
  content: "\e1CB"
}

.icon-regular-wea016:before {
  content: "\e1CC"
}

.icon-regular-wea017:before {
  content: "\e1CD"
}

.icon-regular-wea018:before {
  content: "\e1CE"
}

.icon-regular-wea019:before {
  content: "\e1CF"
}

.icon-regular-wea020:before {
  content: "\e1D0"
}

.icon-regular-wea021:before {
  content: "\e1D1"
}

.icon-regular-wea022:before {
  content: "\e1D2"
}

.icon-regular-wea023:before {
  content: "\e1D3"
}

.icon-regular-wea024:before {
  content: "\e1D4"
}

.icon-regular-wea025:before {
  content: "\e1D5"
}

.icon-regular-wea026:before {
  content: "\e1D6"
}

.icon-regular-wea027:before {
  content: "\e1D7"
}

.icon-regular-wea028:before {
  content: "\e1D8"
}

.icon-regular-wea029:before {
  content: "\e1D9"
}

.icon-regular-wea030:before {
  content: "\e1DA"
}

.icon-regular-wea031:before {
  content: "\e1DB"
}

.icon-regular-wea032:before {
  content: "\e1DC"
}

.icon-regular-wea033:before {
  content: "\e1DD"
}

.icon-regular-wea034:before {
  content: "\e1DE"
}

.icon-regular-wea035:before {
  content: "\e1DF"
}

.icon-regular-wea036:before {
  content: "\e1E0"
}

.icon-regular-wea037:before {
  content: "\e1E1"
}

.icon-regular-wea038:before {
  content: "\e1E2"
}

.icon-regular-wea039:before {
  content: "\e1E3"
}

.icon-regular-wea040:before {
  content: "\e1E4"
}

.icon-regular-wea041:before {
  content: "\e1E5"
}

@font-face {
  font-family: latam-icons-bold;
  font-style: normal;
  font-weight: 400;
  src: url(assets/fonts/latam-icons/eot/Latam_Icons-Bold.eot) format('embedded-opentype');
}

@font-face {
  font-family: latam-icons-bold;
  font-style: normal;
  font-weight: 400;
  src: url(assets/fonts/latam-icons/woff2/Latam_Icons-Bold.woff2) format('woff2'), url(assets/fonts/latam-icons/woff/Latam_Icons-Bold.woff) format('woff'), url(assets/fonts/latam-icons/ttf/Latam_Icons-Bold.ttf) format('truetype');
}

[class*=icon-bold], [class^=icon-bold] {
  font-family: latam-icons-bold;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
}

.icon-bold-air001:before {
  content: "\e043"
}

.icon-bold-air002:before {
  content: "\e044"
}

.icon-bold-air003:before {
  content: "\e045"
}

.icon-bold-air004:before {
  content: "\e046"
}

.icon-bold-air005:before {
  content: "\e047"
}

.icon-bold-air006:before {
  content: "\e048"
}

.icon-bold-air007:before {
  content: "\e049"
}

.icon-bold-air008:before {
  content: "\e04A"
}

.icon-bold-air009:before {
  content: "\e04B"
}

.icon-bold-air010:before {
  content: "\e04C"
}

.icon-bold-air011:before {
  content: "\e04D"
}

.icon-bold-air012:before {
  content: "\e04E"
}

.icon-bold-air013:before {
  content: "\e210"
}

.icon-bold-air014:before {
  content: "\e275"
}

.icon-bold-air015:before {
  content: "\e215"
}

.icon-bold-air016:before {
  content: "\e216"
}

.icon-bold-air017:before {
  content: "\e21D"
}

.icon-bold-air018:before {
  content: "\e23F"
}

.icon-bold-air019:before {
  content: "\e242"
}

.icon-bold-air020:before {
  content: "\e245"
}

.icon-bold-air021:before {
  content: "\e251"
}

.icon-bold-air022:before {
  content: "\e270"
}

.icon-bold-air023:before {
  content: "\e277"
}

.icon-bold-cat001:before {
  content: "\e04F"
}

.icon-bold-cat002:before {
  content: "\e050"
}

.icon-bold-cat003:before {
  content: "\e051"
}

.icon-bold-cat004:before {
  content: "\e052"
}

.icon-bold-cat005:before {
  content: "\e053"
}

.icon-bold-cat006:before {
  content: "\e054"
}

.icon-bold-cat007:before {
  content: "\e055"
}

.icon-bold-cat008:before {
  content: "\e056"
}

.icon-bold-cat009:before {
  content: "\e057"
}

.icon-bold-cat010:before {
  content: "\e058"
}

.icon-bold-cat011:before {
  content: "\e059"
}

.icon-bold-cat012:before {
  content: "\e05A"
}

.icon-bold-cat013:before {
  content: "\e05B"
}

.icon-bold-cat014:before {
  content: "\e05C"
}

.icon-bold-cat015:before {
  content: "\e05D"
}

.icon-bold-cat016:before {
  content: "\e05E"
}

.icon-bold-cat017:before {
  content: "\e24B"
}

.icon-bold-cat018:before {
  content: "\e256"
}

.icon-bold-cat019:before {
  content: "\e272"
}

.icon-bold-cat020:before {
  content: "\e2BF"
}

.icon-bold-car001:before {
  content: "\e1F0"
}

.icon-bold-car002:before {
  content: "\e1F1"
}

.icon-bold-car003:before {
  content: "\e24C"
}

.icon-bold-car004:before {
  content: "\e24D"
}

.icon-bold-car005:before {
  content: "\e24E"
}

.icon-bold-car006:before {
  content: "\e24F"
}

.icon-bold-car007:before {
  content: "\e253"
}

.icon-bold-car008:before {
  content: "\e287"
}

.icon-bold-car009:before {
  content: "\e288"
}

.icon-bold-car010:before {
  content: "\e289"
}

.icon-bold-car011:before {
  content: "\e28A"
}

.icon-bold-car012:before {
  content: "\e29B"
}

.icon-bold-car013:before {
  content: "\e29C"
}

.icon-bold-car014:before {
  content: "\e2B0"
}

.icon-bold-ffp001:before {
  content: "\e1E6"
}

.icon-bold-ffp002:before {
  content: "\e1E7"
}

.icon-bold-ffp003:before {
  content: "\e1E8"
}

.icon-bold-ffp004:before {
  content: "\e1E9"
}

.icon-bold-ffp005:before {
  content: "\e1EA"
}

.icon-bold-ffp006:before {
  content: "\e1EB"
}

.icon-bold-ffp007:before {
  content: "\e1EC"
}

.icon-bold-ffp008:before {
  content: "\e1ED"
}

.icon-bold-ffp009:before {
  content: "\e1EE"
}

.icon-bold-ffp010:before {
  content: "\e1EF"
}

.icon-bold-ffp011:before {
  content: "\e25E"
}

.icon-bold-ffp012:before {
  content: "\e28F"
}

.icon-bold-ffp013:before {
  content: "\e292"
}

.icon-bold-ffp014:before {
  content: "\e2BE"
}

.icon-bold-ife001:before {
  content: "\e1F2"
}

.icon-bold-ife002:before {
  content: "\e1F3"
}

.icon-bold-ife003:before {
  content: "\e1F4"
}

.icon-bold-ife004:before {
  content: "\e1F5"
}

.icon-bold-ife005:before {
  content: "\e1F6"
}

.icon-bold-ife006:before {
  content: "\e1F7"
}

.icon-bold-ife007:before {
  content: "\e1F8"
}

.icon-bold-ife008:before {
  content: "\e1F9"
}

.icon-bold-ife009:before {
  content: "\e1FA"
}

.icon-bold-ife010:before {
  content: "\e1FB"
}

.icon-bold-ife011:before {
  content: "\e1FC"
}

.icon-bold-ife012:before {
  content: "\e1FD"
}

.icon-bold-ife013:before {
  content: "\e1FE"
}

.icon-bold-ife014:before {
  content: "\e1FF"
}

.icon-bold-ife015:before {
  content: "\e200"
}

.icon-bold-ife016:before {
  content: "\e201"
}

.icon-bold-ife017:before {
  content: "\e202"
}

.icon-bold-ife018:before {
  content: "\e203"
}

.icon-bold-ife019:before {
  content: "\e204"
}

.icon-bold-ife020:before {
  content: "\e205"
}

.icon-bold-ife021:before {
  content: "\e206"
}

.icon-bold-ife022:before {
  content: "\e207"
}

.icon-bold-ife023:before {
  content: "\e208"
}

.icon-bold-ife024:before {
  content: "\e209"
}

.icon-bold-ife025:before {
  content: "\e20A"
}

.icon-bold-ife026:before {
  content: "\e20B"
}

.icon-bold-ife027:before {
  content: "\e218"
}

.icon-bold-ife028:before {
  content: "\e221"
}

.icon-bold-ife029:before {
  content: "\e228"
}

.icon-bold-ife030:before {
  content: "\e22A"
}

.icon-bold-ife031:before {
  content: "\e23B"
}

.icon-bold-ife032:before {
  content: "\e23D"
}

.icon-bold-ife033:before {
  content: "\e254"
}

.icon-bold-ife034:before {
  content: "\e263"
}

.icon-bold-ife035:before {
  content: "\e26B"
}

.icon-bold-ife036:before {
  content: "\e278"
}

.icon-bold-ife037:before {
  content: "\e28B"
}

.icon-bold-ife038:before {
  content: "\e28C"
}

.icon-bold-ife039:before {
  content: "\e28D"
}

.icon-bold-ife040:before {
  content: "\e2B1"
}

.icon-bold-int001:before {
  content: "\e191"
}

.icon-bold-int002:before {
  content: "\e192"
}

.icon-bold-int003:before {
  content: "\e193"
}

.icon-bold-int004:before {
  content: "\e194"
}

.icon-bold-int005:before {
  content: "\e195"
}

.icon-bold-int006:before {
  content: "\e196"
}

.icon-bold-int007:before {
  content: "\e197"
}

.icon-bold-int008:before {
  content: "\e198"
}

.icon-bold-int009:before {
  content: "\e199"
}

.icon-bold-int010:before {
  content: "\e19A"
}

.icon-bold-int011:before {
  content: "\e19B"
}

.icon-bold-int012:before {
  content: "\e19C"
}

.icon-bold-int013:before {
  content: "\e19D"
}

.icon-bold-int014:before {
  content: "\e19E"
}

.icon-bold-int015:before {
  content: "\e19F"
}

.icon-bold-int016:before {
  content: "\e1A0"
}

.icon-bold-int017:before {
  content: "\e1A1"
}

.icon-bold-int018:before {
  content: "\e1A2"
}

.icon-bold-int019:before {
  content: "\e1A3"
}

.icon-bold-int020:before {
  content: "\e1A4"
}

.icon-bold-int021:before {
  content: "\e1A5"
}

.icon-bold-int022:before {
  content: "\e1A6"
}

.icon-bold-int023:before {
  content: "\e1A7"
}

.icon-bold-int024:before {
  content: "\e1A8"
}

.icon-bold-int025:before {
  content: "\e1A9"
}

.icon-bold-int026:before {
  content: "\e1AA"
}

.icon-bold-int027:before {
  content: "\e1AB"
}

.icon-bold-int028:before {
  content: "\e1AC"
}

.icon-bold-int029:before {
  content: "\e1AD"
}

.icon-bold-int030:before {
  content: "\e1AE"
}

.icon-bold-int031:before {
  content: "\e1AF"
}

.icon-bold-int032:before {
  content: "\e1B0"
}

.icon-bold-int033:before {
  content: "\e1B1"
}

.icon-bold-int034:before {
  content: "\e1B2"
}

.icon-bold-int035:before {
  content: "\e1B3"
}

.icon-bold-int036:before {
  content: "\e1B4"
}

.icon-bold-int037:before {
  content: "\e1B5"
}

.icon-bold-int038:before {
  content: "\e1B6"
}

.icon-bold-int039:before {
  content: "\e1B7"
}

.icon-bold-int040:before {
  content: "\e1B8"
}

.icon-bold-int041:before {
  content: "\e1B9"
}

.icon-bold-int042:before {
  content: "\e1BA"
}

.icon-bold-int043:before {
  content: "\e1BB"
}

.icon-bold-int044:before {
  content: "\e1BC"
}

.icon-bold-int045:before {
  content: "\e21A"
}

.icon-bold-int046:before {
  content: "\e224"
}

.icon-bold-int047:before {
  content: "\e239"
}

.icon-bold-int048:before {
  content: "\e243"
}

.icon-bold-int049:before {
  content: "\e246"
}

.icon-bold-int050:before {
  content: "\e261"
}

.icon-bold-int051:before {
  content: "\e264"
}

.icon-bold-int052:before {
  content: "\e2A6"
}

.icon-bold-int053:before {
  content: "\e258"
}

.icon-bold-int054:before {
  content: "\e25A"
}

.icon-bold-int055:before {
  content: "\e2B2"
}

.icon-bold-int056:before {
  content: "\e2B4"
}

.icon-bold-int057:before {
  content: "\e2B5"
}

.icon-bold-int058:before {
  content: "\e2B6"
}

.icon-bold-int059:before {
  content: "\e2B3"
}

.icon-bold-int060:before {
  content: "\e2B7"
}

.icon-bold-peo001:before {
  content: "\e05F"
}

.icon-bold-peo002:before {
  content: "\e060"
}

.icon-bold-peo003:before {
  content: "\e061"
}

.icon-bold-peo004:before {
  content: "\e062"
}

.icon-bold-peo005:before {
  content: "\e063"
}

.icon-bold-peo006:before {
  content: "\e064"
}

.icon-bold-peo007:before {
  content: "\e065"
}

.icon-bold-peo008:before {
  content: "\e066"
}

.icon-bold-peo009:before {
  content: "\e067"
}

.icon-bold-peo010:before {
  content: "\e068"
}

.icon-bold-peo011:before {
  content: "\e069"
}

.icon-bold-peo012:before {
  content: "\e06A"
}

.icon-bold-peo013:before {
  content: "\e06B"
}

.icon-bold-peo014:before {
  content: "\e06C"
}

.icon-bold-peo015:before {
  content: "\e06D"
}

.icon-bold-peo016:before {
  content: "\e06E"
}

.icon-bold-peo017:before {
  content: "\e06F"
}

.icon-bold-peo018:before {
  content: "\e070"
}

.icon-bold-peo019:before {
  content: "\e071"
}

.icon-bold-peo020:before {
  content: "\e072"
}

.icon-bold-peo021:before {
  content: "\e073"
}

.icon-bold-peo022:before {
  content: "\e074"
}

.icon-bold-peo023:before {
  content: "\e075"
}

.icon-bold-peo024:before {
  content: "\e076"
}

.icon-bold-peo025:before {
  content: "\e077"
}

.icon-bold-peo026:before {
  content: "\e078"
}

.icon-bold-peo027:before {
  content: "\e079"
}

.icon-bold-peo028:before {
  content: "\e07A"
}

.icon-bold-peo029:before {
  content: "\e07B"
}

.icon-bold-peo030:before {
  content: "\e07C"
}

.icon-bold-peo031:before {
  content: "\e07D"
}

.icon-bold-peo032:before {
  content: "\e07E"
}

.icon-bold-peo033:before {
  content: "\e07F"
}

.icon-bold-peo034:before {
  content: "\e213"
}

.icon-bold-peo035:before {
  content: "\e225"
}

.icon-bold-peo036:before {
  content: "\e226"
}

.icon-bold-peo037:before {
  content: "\e244"
}

.icon-bold-peo038:before {
  content: "\e247"
}

.icon-bold-peo039:before {
  content: "\e248"
}

.icon-bold-peo040:before {
  content: "\e25F"
}

.icon-bold-peo041:before {
  content: "\e290"
}

.icon-bold-peo042:before {
  content: "\e293"
}

.icon-bold-peo043:before {
  content: "\e265"
}

.icon-bold-peo044:before {
  content: "\e267"
}

.icon-bold-peo045:before {
  content: "\e269"
}

.icon-bold-peo046:before {
  content: "\e26C"
}

.icon-bold-peo047:before {
  content: "\e26E"
}

.icon-bold-peo048:before {
  content: "\e299"
}

.icon-bold-peo049:before {
  content: "\e2A7"
}

.icon-bold-peo050:before {
  content: "\e2A9"
}

.icon-bold-peo051:before {
  content: "\e2B8"
}

.icon-bold-peo052:before {
  content: "\e2B9"
}

.icon-bold-peo053:before {
  content: "\e2BB"
}

.icon-bold-peo054:before {
  content: "\e2BC"
}

.icon-bold-sec001:before {
  content: "\e000"
}

.icon-bold-sec002:before {
  content: "\e001"
}

.icon-bold-sec003:before {
  content: "\e002"
}

.icon-bold-sec004:before {
  content: "\e003"
}

.icon-bold-sec005:before {
  content: "\e004"
}

.icon-bold-sec006:before {
  content: "\e005"
}

.icon-bold-sec007:before {
  content: "\e006"
}

.icon-bold-sec008:before {
  content: "\e007"
}

.icon-bold-sec009:before {
  content: "\e008"
}

.icon-bold-sec010:before {
  content: "\e009"
}

.icon-bold-sec011:before {
  content: "\e00A"
}

.icon-bold-sec012:before {
  content: "\e00B"
}

.icon-bold-sec013:before {
  content: "\e00C"
}

.icon-bold-sec014:before {
  content: "\e00D"
}

.icon-bold-sec015:before {
  content: "\e00E"
}

.icon-bold-sec016:before {
  content: "\e00F"
}

.icon-bold-sec017:before {
  content: "\e010"
}

.icon-bold-sec018:before {
  content: "\e011"
}

.icon-bold-sec019:before {
  content: "\e012"
}

.icon-bold-sec020:before {
  content: "\e013"
}

.icon-bold-sec021:before {
  content: "\e014"
}

.icon-bold-sec022:before {
  content: "\e015"
}

.icon-bold-sec023:before {
  content: "\e016"
}

.icon-bold-sec024:before {
  content: "\e017"
}

.icon-bold-sec025:before {
  content: "\e018"
}

.icon-bold-sec026:before {
  content: "\e019"
}

.icon-bold-sec027:before {
  content: "\e01A"
}

.icon-bold-sec028:before {
  content: "\e01B"
}

.icon-bold-sec029:before {
  content: "\e01C"
}

.icon-bold-sec030:before {
  content: "\e01D"
}

.icon-bold-sec031:before {
  content: "\e01E"
}

.icon-bold-sec032:before {
  content: "\e01F"
}

.icon-bold-sec033:before {
  content: "\e020"
}

.icon-bold-sec034:before {
  content: "\e021"
}

.icon-bold-sec035:before {
  content: "\e022"
}

.icon-bold-sec036:before {
  content: "\e023"
}

.icon-bold-sec037:before {
  content: "\e024"
}

.icon-bold-sec038:before {
  content: "\e025"
}

.icon-bold-sec039:before {
  content: "\e026"
}

.icon-bold-sec040:before {
  content: "\e027"
}

.icon-bold-sec041:before {
  content: "\e028"
}

.icon-bold-sec042:before {
  content: "\e029"
}

.icon-bold-sec043:before {
  content: "\e02A"
}

.icon-bold-sec044:before {
  content: "\e02B"
}

.icon-bold-sec045:before {
  content: "\e02C"
}

.icon-bold-sec046:before {
  content: "\e02D"
}

.icon-bold-sec047:before {
  content: "\e02E"
}

.icon-bold-sec048:before {
  content: "\e02F"
}

.icon-bold-sec049:before {
  content: "\e030"
}

.icon-bold-sec050:before {
  content: "\e031"
}

.icon-bold-sec051:before {
  content: "\e032"
}

.icon-bold-sec052:before {
  content: "\e033"
}

.icon-bold-sec053:before {
  content: "\e034"
}

.icon-bold-sec054:before {
  content: "\e035"
}

.icon-bold-sec055:before {
  content: "\e036"
}

.icon-bold-sec056:before {
  content: "\e037"
}

.icon-bold-sec057:before {
  content: "\e038"
}

.icon-bold-sec058:before {
  content: "\e039"
}

.icon-bold-sec059:before {
  content: "\e03A"
}

.icon-bold-sec060:before {
  content: "\e03B"
}

.icon-bold-sec061:before {
  content: "\e03C"
}

.icon-bold-sec062:before {
  content: "\e03D"
}

.icon-bold-sec063:before {
  content: "\e03E"
}

.icon-bold-sec064:before {
  content: "\e03F"
}

.icon-bold-sec065:before {
  content: "\e040"
}

.icon-bold-sec066:before {
  content: "\e041"
}

.icon-bold-sec067:before {
  content: "\e042"
}

.icon-bold-sec068:before {
  content: "\e21B"
}

.icon-bold-sec069:before {
  content: "\e21C"
}

.icon-bold-sec070:before {
  content: "\e273"
}

.icon-bold-sec071:before {
  content: "\e274"
}

.icon-bold-sec072:before {
  content: "\e296"
}

.icon-bold-sec073:before {
  content: "\e297"
}

.icon-bold-sec074:before {
  content: "\e298"
}

.icon-bold-sec075:before {
  content: "\e29A"
}

.icon-bold-sec076:before {
  content: "\e2B3"
}

.icon-bold-sig001:before {
  content: "\e080"
}

.icon-bold-sig002:before {
  content: "\e081"
}

.icon-bold-sig003:before {
  content: "\e082"
}

.icon-bold-sig004:before {
  content: "\e083"
}

.icon-bold-sig005:before {
  content: "\e084"
}

.icon-bold-sig006:before {
  content: "\e085"
}

.icon-bold-sig007:before {
  content: "\e086"
}

.icon-bold-sig008:before {
  content: "\e087"
}

.icon-bold-sig009:before {
  content: "\e088"
}

.icon-bold-sig010:before {
  content: "\e089"
}

.icon-bold-sig011:before {
  content: "\e08A"
}

.icon-bold-sig012:before {
  content: "\e08B"
}

.icon-bold-sig013:before {
  content: "\e08C"
}

.icon-bold-sig014:before {
  content: "\e08D"
}

.icon-bold-sig015:before {
  content: "\e08E"
}

.icon-bold-sig016:before {
  content: "\e08F"
}

.icon-bold-sig017:before {
  content: "\e090"
}

.icon-bold-sig018:before {
  content: "\e091"
}

.icon-bold-sig019:before {
  content: "\e092"
}

.icon-bold-sig020:before {
  content: "\e093"
}

.icon-bold-sig021:before {
  content: "\e094"
}

.icon-bold-sig022:before {
  content: "\e095"
}

.icon-bold-sig023:before {
  content: "\e096"
}

.icon-bold-sig024:before {
  content: "\e097"
}

.icon-bold-sig025:before {
  content: "\e098"
}

.icon-bold-sig026:before {
  content: "\e099"
}

.icon-bold-sig027:before {
  content: "\e09A"
}

.icon-bold-sig028:before {
  content: "\e09B"
}

.icon-bold-sig029:before {
  content: "\e09C"
}

.icon-bold-sig030:before {
  content: "\e09D"
}

.icon-bold-sig031:before {
  content: "\e09E"
}

.icon-bold-sig032:before {
  content: "\e09F"
}

.icon-bold-sig033:before {
  content: "\e0A0"
}

.icon-bold-sig034:before {
  content: "\e0A1"
}

.icon-bold-sig035:before {
  content: "\e0A2"
}

.icon-bold-sig036:before {
  content: "\e0A3"
}

.icon-bold-sig037:before {
  content: "\e0A4"
}

.icon-bold-sig038:before {
  content: "\e0A5"
}

.icon-bold-sig039:before {
  content: "\e0A6"
}

.icon-bold-sig040:before {
  content: "\e0A7"
}

.icon-bold-sig041:before {
  content: "\e0A8"
}

.icon-bold-sig042:before {
  content: "\e0A9"
}

.icon-bold-sig043:before {
  content: "\e0AA"
}

.icon-bold-sig044:before {
  content: "\e0AB"
}

.icon-bold-sig045:before {
  content: "\e0AC"
}

.icon-bold-sig046:before {
  content: "\e0AD"
}

.icon-bold-sig047:before {
  content: "\e0AE"
}

.icon-bold-sig048:before {
  content: "\e0AF"
}

.icon-bold-sig049:before {
  content: "\e0B0"
}

.icon-bold-sig050:before {
  content: "\e0B1"
}

.icon-bold-sig051:before {
  content: "\e0B2"
}

.icon-bold-sig052:before {
  content: "\e0B3"
}

.icon-bold-sig053:before {
  content: "\e0B4"
}

.icon-bold-sig054:before {
  content: "\e0B5"
}

.icon-bold-sig055:before {
  content: "\e0B6"
}

.icon-bold-sig056:before {
  content: "\e0B7"
}

.icon-bold-sig057:before {
  content: "\e0B8"
}

.icon-bold-sig058:before {
  content: "\e0B9"
}

.icon-bold-sig059:before {
  content: "\e0BA"
}

.icon-bold-sig060:before {
  content: "\e0BB"
}

.icon-bold-sig061:before {
  content: "\e0BC"
}

.icon-bold-sig062:before {
  content: "\e0BD"
}

.icon-bold-sig063:before {
  content: "\e0BE"
}

.icon-bold-sig064:before {
  content: "\e0BF"
}

.icon-bold-sig065:before {
  content: "\e0C0"
}

.icon-bold-sig066:before {
  content: "\e0C1"
}

.icon-bold-sig067:before {
  content: "\e0C2"
}

.icon-bold-sig068:before {
  content: "\e0C3"
}

.icon-bold-sig069:before {
  content: "\e0C4"
}

.icon-bold-sig070:before {
  content: "\e0C5"
}

.icon-bold-sig071:before {
  content: "\e0C6"
}

.icon-bold-sig072:before {
  content: "\e0C7"
}

.icon-bold-sig073:before {
  content: "\e0C8"
}

.icon-bold-sig074:before {
  content: "\e0C9"
}

.icon-bold-sig075:before {
  content: "\e0CA"
}

.icon-bold-sig076:before {
  content: "\e0CB"
}

.icon-bold-sig077:before {
  content: "\e0CC"
}

.icon-bold-sig078:before {
  content: "\e0CD"
}

.icon-bold-sig079:before {
  content: "\e0CE"
}

.icon-bold-sig080:before {
  content: "\e0CF"
}

.icon-bold-sig081:before {
  content: "\e0D0"
}

.icon-bold-sig082:before {
  content: "\e0D1"
}

.icon-bold-sig083:before {
  content: "\e0D2"
}

.icon-bold-sig084:before {
  content: "\e0D3"
}

.icon-bold-sig085:before {
  content: "\e0D4"
}

.icon-bold-sig086:before {
  content: "\e0D5"
}

.icon-bold-sig087:before {
  content: "\e0D6"
}

.icon-bold-sig088:before {
  content: "\e0D7"
}

.icon-bold-sig089:before {
  content: "\e0D8"
}

.icon-bold-sig090:before {
  content: "\e0D9"
}

.icon-bold-sig091:before {
  content: "\e0DA"
}

.icon-bold-sig092:before {
  content: "\e0DB"
}

.icon-bold-sig093:before {
  content: "\e0DC"
}

.icon-bold-sig094:before {
  content: "\e0DD"
}

.icon-bold-sig095:before {
  content: "\e0DE"
}

.icon-bold-sig096:before {
  content: "\e0DF"
}

.icon-bold-sig097:before {
  content: "\e0E0"
}

.icon-bold-sig098:before {
  content: "\e0E1"
}

.icon-bold-sig099:before {
  content: "\e0E2"
}

.icon-bold-sig100:before {
  content: "\e0E3"
}

.icon-bold-sig101:before {
  content: "\e0E4"
}

.icon-bold-sig102:before {
  content: "\e0E5"
}

.icon-bold-sig103:before {
  content: "\e0E6"
}

.icon-bold-sig104:before {
  content: "\e0E7"
}

.icon-bold-sig105:before {
  content: "\e0E8"
}

.icon-bold-sig106:before {
  content: "\e0E9"
}

.icon-bold-sig107:before {
  content: "\e0EA"
}

.icon-bold-sig108:before {
  content: "\e0EB"
}

.icon-bold-sig109:before {
  content: "\e0EC"
}

.icon-bold-sig110:before {
  content: "\e0ED"
}

.icon-bold-sig111:before {
  content: "\e0EE"
}

.icon-bold-sig112:before {
  content: "\e0EF"
}

.icon-bold-sig113:before {
  content: "\e0F0"
}

.icon-bold-sig114:before {
  content: "\e0F1"
}

.icon-bold-sig115:before {
  content: "\e0F2"
}

.icon-bold-sig116:before {
  content: "\e0F3"
}

.icon-bold-sig117:before {
  content: "\e0F4"
}

.icon-bold-sig118:before {
  content: "\e0F5"
}

.icon-bold-sig119:before {
  content: "\e0F6"
}

.icon-bold-sig120:before {
  content: "\e0F7"
}

.icon-bold-sig121:before {
  content: "\e0F8"
}

.icon-bold-sig122:before {
  content: "\e0F9"
}

.icon-bold-sig123:before {
  content: "\e0FA"
}

.icon-bold-sig124:before {
  content: "\e0FB"
}

.icon-bold-sig125:before {
  content: "\e0FC"
}

.icon-bold-sig126:before {
  content: "\e0FD"
}

.icon-bold-sig127:before {
  content: "\e0FE"
}

.icon-bold-sig128:before {
  content: "\e0FF"
}

.icon-bold-sig129:before {
  content: "\e100"
}

.icon-bold-sig130:before {
  content: "\e101"
}

.icon-bold-sig131:before {
  content: "\e219"
}

.icon-bold-sig132:before {
  content: "\e21F"
}

.icon-bold-sig133:before {
  content: "\e227"
}

.icon-bold-sig134:before {
  content: "\e23A"
}

.icon-bold-sig135:before {
  content: "\e240"
}

.icon-bold-sig136:before {
  content: "\e241"
}

.icon-bold-sig137:before {
  content: "\e249"
}

.icon-bold-sig138:before {
  content: "\e24A"
}

.icon-bold-sig139:before {
  content: "\e252"
}

.icon-bold-sig140:before {
  content: "\e257"
}

.icon-bold-sig141:before {
  content: "\e25C"
}

.icon-bold-sig142:before {
  content: "\e260"
}

.icon-bold-sig143:before {
  content: "\e291"
}

.icon-bold-sig144:before {
  content: "\e294"
}

.icon-bold-sig145:before {
  content: "\e262"
}

.icon-bold-sig146:before {
  content: "\e266"
}

.icon-bold-sig147:before {
  content: "\e268"
}

.icon-bold-sig148:before {
  content: "\e26A"
}

.icon-bold-sig149:before {
  content: "\e26D"
}

.icon-bold-sig150:before {
  content: "\e26E"
}

.icon-bold-sig151:before {
  content: "\e271"
}

.icon-bold-sig152:before {
  content: "\e276"
}

.icon-bold-sig153:before {
  content: "\e285"
}

.icon-bold-sig154:before {
  content: "\e28E"
}

.icon-bold-sig155:before {
  content: "\e29B"
}

.icon-bold-sig156:before {
  content: "\e29C"
}

.icon-bold-sig157:before {
  content: "\e29D"
}

.icon-bold-sig158:before {
  content: "\e29E"
}

.icon-bold-sig159:before {
  content: "\e29F"
}

.icon-bold-sig160:before {
  content: "\e2A0"
}

.icon-bold-sig161:before {
  content: "\e2A1"
}

.icon-bold-sig162:before {
  content: "\e2A2"
}

.icon-bold-sig163:before {
  content: "\e2A3"
}

.icon-bold-sig164:before {
  content: "\e2A4"
}

.icon-bold-sig165:before {
  content: "\e2A5"
}

.icon-bold-sig166:before {
  content: "\e2A8"
}

.icon-bold-sig167:before {
  content: "\e2AA"
}

.icon-bold-sig168:before {
  content: "\e2B8"
}

.icon-bold-sig169:before {
  content: "\e2B9"
}

.icon-bold-sig170:before {
  content: "\e2BB"
}

.icon-bold-sig171:before {
  content: "\e2BC"
}

.icon-bold-sig172:before {
  content: "\e2BA"
}

.icon-bold-sig173:before {
  content: "\e2BD"
}

.icon-bold-tra001:before {
  content: "\e158"
}

.icon-bold-tra002:before {
  content: "\e159"
}

.icon-bold-tra003:before {
  content: "\e15A"
}

.icon-bold-tra004:before {
  content: "\e15B"
}

.icon-bold-tra005:before {
  content: "\e15C"
}

.icon-bold-tra006:before {
  content: "\e15D"
}

.icon-bold-tra007:before {
  content: "\e15E"
}

.icon-bold-tra008:before {
  content: "\e15F"
}

.icon-bold-tra009:before {
  content: "\e160"
}

.icon-bold-tra010:before {
  content: "\e161"
}

.icon-bold-tra011:before {
  content: "\e162"
}

.icon-bold-tra012:before {
  content: "\e163"
}

.icon-bold-tra013:before {
  content: "\e164"
}

.icon-bold-tra014:before {
  content: "\e165"
}

.icon-bold-tra015:before {
  content: "\e166"
}

.icon-bold-tra016:before {
  content: "\e167"
}

.icon-bold-tra017:before {
  content: "\e168"
}

.icon-bold-tra018:before {
  content: "\e169"
}

.icon-bold-tra019:before {
  content: "\e16A"
}

.icon-bold-tra020:before {
  content: "\e16B"
}

.icon-bold-tra021:before {
  content: "\e16C"
}

.icon-bold-tra022:before {
  content: "\e16D"
}

.icon-bold-tra023:before {
  content: "\e16E"
}

.icon-bold-tra024:before {
  content: "\e16F"
}

.icon-bold-tra025:before {
  content: "\e170"
}

.icon-bold-tra026:before {
  content: "\e171"
}

.icon-bold-tra027:before {
  content: "\e172"
}

.icon-bold-tra028:before {
  content: "\e173"
}

.icon-bold-tra029:before {
  content: "\e174"
}

.icon-bold-tra030:before {
  content: "\e175"
}

.icon-bold-tra031:before {
  content: "\e176"
}

.icon-bold-tra032:before {
  content: "\e177"
}

.icon-bold-tra033:before {
  content: "\e178"
}

.icon-bold-tra034:before {
  content: "\e179"
}

.icon-bold-tra035:before {
  content: "\e17A"
}

.icon-bold-tra036:before {
  content: "\e17B"
}

.icon-bold-tra037:before {
  content: "\e17C"
}

.icon-bold-tra038:before {
  content: "\e17D"
}

.icon-bold-tra039:before {
  content: "\e17E"
}

.icon-bold-tra040:before {
  content: "\e17F"
}

.icon-bold-tra041:before {
  content: "\e180"
}

.icon-bold-tra042:before {
  content: "\e181"
}

.icon-bold-tra043:before {
  content: "\e182"
}

.icon-bold-tra044:before {
  content: "\e183"
}

.icon-bold-tra045:before {
  content: "\e184"
}

.icon-bold-tra046:before {
  content: "\e185"
}

.icon-bold-tra047:before {
  content: "\e186"
}

.icon-bold-tra048:before {
  content: "\e187"
}

.icon-bold-tra049:before {
  content: "\e188"
}

.icon-bold-tra050:before {
  content: "\e189"
}

.icon-bold-tra051:before {
  content: "\e18A"
}

.icon-bold-tra052:before {
  content: "\e18B"
}

.icon-bold-tra053:before {
  content: "\e18C"
}

.icon-bold-tra054:before {
  content: "\e18D"
}

.icon-bold-tra055:before {
  content: "\e18E"
}

.icon-bold-tra056:before {
  content: "\e18F"
}

.icon-bold-tra057:before {
  content: "\e190"
}

.icon-bold-tra058:before {
  content: "\e20C"
}

.icon-bold-tra059:before {
  content: "\e20D"
}

.icon-bold-tra060:before {
  content: "\e20F"
}

.icon-bold-tra061:before {
  content: "\e21E"
}

.icon-bold-tra062:before {
  content: "\e220"
}

.icon-bold-tra063:before {
  content: "\e222"
}

.icon-bold-tra064:before {
  content: "\e22C"
}

.icon-bold-tra065:before {
  content: "\e22D"
}

.icon-bold-tra066:before {
  content: "\e22E"
}

.icon-bold-tra067:before {
  content: "\e22F"
}

.icon-bold-tra068:before {
  content: "\e230"
}

.icon-bold-tra069:before {
  content: "\e231"
}

.icon-bold-tra070:before {
  content: "\e232"
}

.icon-bold-tra071:before {
  content: "\e233"
}

.icon-bold-tra072:before {
  content: "\e234"
}

.icon-bold-tra073:before {
  content: "\e235"
}

.icon-bold-tra074:before {
  content: "\e236"
}

.icon-bold-tra075:before {
  content: "\e237"
}

.icon-bold-tra076:before {
  content: "\e238"
}

.icon-bold-tra077:before {
  content: "\e25D"
}

.icon-bold-tra078:before {
  content: "\e279"
}

.icon-bold-tra079:before {
  content: "\e27A"
}

.icon-bold-tra080:before {
  content: "\e27B"
}

.icon-bold-tra081:before {
  content: "\e27C"
}

.icon-bold-tra082:before {
  content: "\e27D"
}

.icon-bold-tra083:before {
  content: "\e27E"
}

.icon-bold-tra084:before {
  content: "\e27F"
}

.icon-bold-tra085:before {
  content: "\e280"
}

.icon-bold-tra086:before {
  content: "\e281"
}

.icon-bold-tra087:before {
  content: "\e282"
}

.icon-bold-tra088:before {
  content: "\e283"
}

.icon-bold-tra089:before {
  content: "\e284"
}

.icon-bold-tra090:before {
  content: "\e286"
}

.icon-bold-tra091:before {
  content: "\e295"
}

.icon-bold-tra092:before {
  content: "\e2AB"
}

.icon-bold-tra093:before {
  content: "\e2AC"
}

.icon-bold-tra094:before {
  content: "\e2AD"
}

.icon-bold-tra095:before {
  content: "\e2AF"
}

.icon-bold-tra096:before {
  content: "\e2B2"
}

.icon-bold-tra097:before {
  content: "\e2B5"
}

.icon-bold-uid001:before {
  content: "\e102"
}

.icon-bold-uid002:before {
  content: "\e103"
}

.icon-bold-uid003:before {
  content: "\e104"
}

.icon-bold-uid004:before {
  content: "\e105"
}

.icon-bold-uid005:before {
  content: "\e106"
}

.icon-bold-uid006:before {
  content: "\e107"
}

.icon-bold-uid007:before {
  content: "\e108"
}

.icon-bold-uid008:before {
  content: "\e109"
}

.icon-bold-uid009:before {
  content: "\e10A"
}

.icon-bold-uid010:before {
  content: "\e10B"
}

.icon-bold-uid011:before {
  content: "\e10C"
}

.icon-bold-uid012:before {
  content: "\e10D"
}

.icon-bold-uid013:before {
  content: "\e10E"
}

.icon-bold-uid014:before {
  content: "\e10F"
}

.icon-bold-uid015:before {
  content: "\e110"
}

.icon-bold-uid016:before {
  content: "\e111"
}

.icon-bold-uid017:before {
  content: "\e112"
}

.icon-bold-uid018:before {
  content: "\e113"
}

.icon-bold-uid019:before {
  content: "\e114"
}

.icon-bold-uid020:before {
  content: "\e115"
}

.icon-bold-uid021:before {
  content: "\e116"
}

.icon-bold-uid022:before {
  content: "\e117"
}

.icon-bold-uid023:before {
  content: "\e118"
}

.icon-bold-uid024:before {
  content: "\e119"
}

.icon-bold-uid025:before {
  content: "\e11A"
}

.icon-bold-uid026:before {
  content: "\e11B"
}

.icon-bold-uid027:before {
  content: "\e11C"
}

.icon-bold-uid028:before {
  content: "\e11D"
}

.icon-bold-uid029:before {
  content: "\e11E"
}

.icon-bold-uid030:before {
  content: "\e11F"
}

.icon-bold-uid031:before {
  content: "\e120"
}

.icon-bold-uid032:before {
  content: "\e121"
}

.icon-bold-uid033:before {
  content: "\e122"
}

.icon-bold-uid034:before {
  content: "\e123"
}

.icon-bold-uid035:before {
  content: "\e124"
}

.icon-bold-uid036:before {
  content: "\e125"
}

.icon-bold-uid037:before {
  content: "\e126"
}

.icon-bold-uid038:before {
  content: "\e127"
}

.icon-bold-uid039:before {
  content: "\e128"
}

.icon-bold-uid040:before {
  content: "\e129"
}

.icon-bold-uid041:before {
  content: "\e12A"
}

.icon-bold-uid042:before {
  content: "\e12B"
}

.icon-bold-uid043:before {
  content: "\e12C"
}

.icon-bold-uid044:before {
  content: "\e12D"
}

.icon-bold-uid045:before {
  content: "\e12E"
}

.icon-bold-uid046:before {
  content: "\e12F"
}

.icon-bold-uid047:before {
  content: "\e130"
}

.icon-bold-uid048:before {
  content: "\e131"
}

.icon-bold-uid049:before {
  content: "\e132"
}

.icon-bold-uid050:before {
  content: "\e133"
}

.icon-bold-uid051:before {
  content: "\e134"
}

.icon-bold-uid052:before {
  content: "\e135"
}

.icon-bold-uid053:before {
  content: "\e136"
}

.icon-bold-uid054:before {
  content: "\e137"
}

.icon-bold-uid055:before {
  content: "\e138"
}

.icon-bold-uid056:before {
  content: "\e139"
}

.icon-bold-uid057:before {
  content: "\e13A"
}

.icon-bold-uid058:before {
  content: "\e13B"
}

.icon-bold-uid059:before {
  content: "\e13C"
}

.icon-bold-uid060:before {
  content: "\e13D"
}

.icon-bold-uid061:before {
  content: "\e13E"
}

.icon-bold-uid062:before {
  content: "\e13F"
}

.icon-bold-uid063:before {
  content: "\e140"
}

.icon-bold-uid064:before {
  content: "\e141"
}

.icon-bold-uid065:before {
  content: "\e142"
}

.icon-bold-uid066:before {
  content: "\e143"
}

.icon-bold-uid067:before {
  content: "\e144"
}

.icon-bold-uid068:before {
  content: "\e145"
}

.icon-bold-uid069:before {
  content: "\e146"
}

.icon-bold-uid070:before {
  content: "\e147"
}

.icon-bold-uid071:before {
  content: "\e148"
}

.icon-bold-uid072:before {
  content: "\e149"
}

.icon-bold-uid073:before {
  content: "\e14A"
}

.icon-bold-uid074:before {
  content: "\e14B"
}

.icon-bold-uid075:before {
  content: "\e14C"
}

.icon-bold-uid076:before {
  content: "\e14D"
}

.icon-bold-uid077:before {
  content: "\e14E"
}

.icon-bold-uid078:before {
  content: "\e14F"
}

.icon-bold-uid079:before {
  content: "\e150"
}

.icon-bold-uid080:before {
  content: "\e151"
}

.icon-bold-uid081:before {
  content: "\e152"
}

.icon-bold-uid082:before {
  content: "\e153"
}

.icon-bold-uid083:before {
  content: "\e154"
}

.icon-bold-uid084:before {
  content: "\e155"
}

.icon-bold-uid085:before {
  content: "\e156"
}

.icon-bold-uid086:before {
  content: "\e157"
}

.icon-bold-uid087:before {
  content: "\e20E"
}

.icon-bold-uid088:before {
  content: "\e217"
}

.icon-bold-uid089:before {
  content: "\e223"
}

.icon-bold-uid090:before {
  content: "\e229"
}

.icon-bold-uid091:before {
  content: "\e22B"
}

.icon-bold-uid092:before {
  content: "\e23C"
}

.icon-bold-uid093:before {
  content: "\e23E"
}

.icon-bold-uid094:before {
  content: "\e250"
}

.icon-bold-uid095:before {
  content: "\e255"
}

.icon-bold-uid096:before {
  content: "\e259"
}

.icon-bold-uid097:before {
  content: "\e25B"
}

.icon-bold-uid098:before {
  content: "\e2AE"
}

.icon-bold-uid099:before {
  content: "\e2BD"
}

.icon-bold-wea001:before {
  content: "\e1BD"
}

.icon-bold-wea002:before {
  content: "\e1BE"
}

.icon-bold-wea003:before {
  content: "\e1BF"
}

.icon-bold-wea004:before {
  content: "\e1C0"
}

.icon-bold-wea005:before {
  content: "\e1C1"
}

.icon-bold-wea006:before {
  content: "\e1C2"
}

.icon-bold-wea007:before {
  content: "\e1C3"
}

.icon-bold-wea008:before {
  content: "\e1C4"
}

.icon-bold-wea009:before {
  content: "\e1C5"
}

.icon-bold-wea010:before {
  content: "\e1C6"
}

.icon-bold-wea011:before {
  content: "\e1C7"
}

.icon-bold-wea012:before {
  content: "\e1C8"
}

.icon-bold-wea013:before {
  content: "\e1C9"
}

.icon-bold-wea014:before {
  content: "\e1CA"
}

.icon-bold-wea015:before {
  content: "\e1CB"
}

.icon-bold-wea016:before {
  content: "\e1CC"
}

.icon-bold-wea017:before {
  content: "\e1CD"
}

.icon-bold-wea018:before {
  content: "\e1CE"
}

.icon-bold-wea019:before {
  content: "\e1CF"
}

.icon-bold-wea020:before {
  content: "\e1D0"
}

.icon-bold-wea021:before {
  content: "\e1D1"
}

.icon-bold-wea022:before {
  content: "\e1D2"
}

.icon-bold-wea023:before {
  content: "\e1D3"
}

.icon-bold-wea024:before {
  content: "\e1D4"
}

.icon-bold-wea025:before {
  content: "\e1D5"
}

.icon-bold-wea026:before {
  content: "\e1D6"
}

.icon-bold-wea027:before {
  content: "\e1D7"
}

.icon-bold-wea028:before {
  content: "\e1D8"
}

.icon-bold-wea029:before {
  content: "\e1D9"
}

.icon-bold-wea030:before {
  content: "\e1DA"
}

.icon-bold-wea031:before {
  content: "\e1DB"
}

.icon-bold-wea032:before {
  content: "\e1DC"
}

.icon-bold-wea033:before {
  content: "\e1DD"
}

.icon-bold-wea034:before {
  content: "\e1DE"
}

.icon-bold-wea035:before {
  content: "\e1DF"
}

.icon-bold-wea036:before {
  content: "\e1E0"
}

.icon-bold-wea037:before {
  content: "\e1E1"
}

.icon-bold-wea038:before {
  content: "\e1E2"
}

.icon-bold-wea039:before {
  content: "\e1E3"
}

.icon-bold-wea040:before {
  content: "\e1E4"
}

.icon-bold-wea041:before {
  content: "\e1E5"
}



.mat-mdc-outlined-button[disabled][disabled] {
  /* padding-top: 2px; */
  top: -3px!important; 
}